<template>
  <div class="formula">
    <Codeblock :code="value" style="height: 120px" @click="edit()" />
  </div>
</template>

<script>
import renderModal from "@/utils/render-modal";
import Modal from "./Modal";
import Codeblock from "./Codeblock";

export default {
  components: { Codeblock },
  props: {
    value: String
  },
  methods: {
    async edit() {
      const value = await renderModal(Modal, {
        defaultValue: this.value,
        getContainer: () => this.$el
      });
      this.$emit("input", value);
    }
  }
};
</script>
