<script>
import CellContentRadio from "../renders/CellContentRadio.vue";

export default {
  functional: true,
  render(h, context) {
    const props = context.props || {};
    const classes = [context.data.staticClass, context.data.class];
    return (
      <CellContentRadio
        class={classes}
        column={props.column}
        value={props.visualValue}
      />
    );
  }
};
</script>
