<template>
  <a-modal
    centered
    :closable="false"
    :width="780"
    :mask="false"
    :body-style="{ padding: 0 }"
    :get-container="getContainer"
    :footer="null"
  >
    <Doc @cancel="$reject()" @confirm="onConfirm()" />
    <div class="editor-wrapper">
      <div class="editor">
        <Editor v-model="value" />
      </div>
      <div class="ant-modal-footer">
        <a-button @click="$reject()">取消</a-button>
        <a-button type="primary" @click="onConfirm()">
          完成
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import Doc from "./ModalDoc";

export default {
  components: {
    Doc,
    Editor: () => ({
      // eslint-disable-next-line prettier/prettier
      component: import(/* webpackChunkName: "monaco-formula" */ "./ModalEditor"),
      loading: {
        // eslint-disable-next-line no-unused-vars
        render(h) {
          return (
            <a-icon
              spin
              type="loading"
              class="ndl-flex ndl-flex--center ndl-flex--middle"
              style="height: 100%; font-size: 24px; color: #1890ff"
            />
          );
        }
      },
      delay: 0
    })
  },
  props: {
    defaultValue: String,
    getContainer: Function,
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      value: this.defaultValue || ""
    };
  },
  methods: {
    onConfirm() {
      this.$resolve(this.value);
    }
  }
};
</script>

<style lang="less" scoped>
.ant-modal-root /deep/ .ant-modal-body {
  display: flex;
  height: 420px;
  .doc {
    flex: 1 1 0;
  }
  .editor-wrapper {
    width: 350px;
    display: flex;
    flex-direction: column;
  }
  .editor {
    flex: 1 1 0;
  }
}
</style>
