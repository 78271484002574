<template>
  <div class="pane-wrapper">
    <header class="pane-header">
      <input @input="debouncedInput" type="text" placeholder="查找" />
      <i class="icon ndl-icon-search"></i>
    </header>
    <VirtualList
      ref="list"
      :data-component="$data.$columnItem"
      :data-sources="computedColumns"
      :estimate-size="35"
      :extra-props="extraProps"
      data-key="columnId"
      class="list-wrapper"
    />
    <footer v-if="showFooter" class="pane-footer">
      <span class="footer-btn" @click="toggleAll(true)">
        选择全部({{ computedColumns.length }})
      </span>
      <span class="divider"></span>
      <span class="footer-btn" @click="toggleAll(false)">
        取消全部({{ countSelected }})
      </span>
    </footer>
  </div>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import debounce from "lodash/debounce";
import ColumnItem from "./column-item";

/**
 * @typedef {{
 *    columnType: string,
 *    columnName: string,
 *    visible: boolean
 * }} Column
 */
export default {
  inheritAttrs: false,
  components: { VirtualList },
  props: {
    /**
     * @type {Column[]}
     */
    columns: {
      type: Array,
      required: true
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: true
    },
    onSelect: Function,
    // 由 contexify 组件注入
    hideContext: Function
  },
  data() {
    return {
      // $ _ 前缀的属性不是响应式的
      $columnItem: ColumnItem,
      filterKey: ""
    };
  },
  computed: {
    extraProps() {
      const { onSelect, showCheckbox } = this;
      return { onSelect, showCheckbox, $selectColumn: this };
    },
    computedColumns() {
      const fieldList = this.columns;
      const key = this.filterKey;
      if (!key) {
        return fieldList;
      }
      return fieldList.filter(item => {
        const name = item.columnName || "";
        return name.toLowerCase().indexOf(key) >= 0;
      });
    },
    countSelected() {
      return this.computedColumns.filter(item => item.visible).length;
    }
  },
  methods: {
    // ⚠️ 这里声明 debounced 方法会导致该组件所有实例的输入都被 debounce
    // 不过这里不会有影响，一者不会同时存在多个组件输入，二者这个组件不会存在多个实例
    // 但 debounce 方法在多个实例中的潜在问题不可忽略
    debouncedInput: debounce(function onInput(evt) {
      this.filterKey = evt.target.value.trim().toLowerCase();
    }, 500),
    toggleAll(bool = false) {
      this.computedColumns.forEach(item => (item.visible = bool));
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.pane-header {
  position: relative;
  padding: 8px 24px 8px 8px;
  border-bottom: solid 1px #ebebeb;
  line-height: 1.5;
  input {
    border: none;
    outline: none;
    display: block;
    background: transparent;
    padding: 3px 8px;
    font-size: 13px;
  }
  input::placeholder {
    color: #bfbfbf;
  }
  input:focus + .icon {
    color: @primary-color;
  }
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #bfbfbf;
  }
}

.pane-footer {
  display: flex;
  font-size: 13px;
  padding: 12px 8px;
  align-items: center;
  user-select: none;
  .footer-btn {
    width: 0;
    flex: 1;
    cursor: pointer;
    color: #434343;
    text-align: center;
  }
  .footer-btn:hover {
    opacity: 0.8;
  }
  .divider {
    height: 12px;
    width: 1px;
    background: #ebebeb;
  }
}

@min-height: 140px;
@max-height: 320px;
@color: fade(black, 65%);
@highlight-bg: @blue-1;
@highlight-color: fade(black, 65%);
@radius: 4px;
@cursor: default;
@decoration: none;
@align-items: center;
.list-wrapper {
  padding: 4px 8px;
  max-height: @max-height;
  min-height: @min-height;
  width: 240px;
  overflow: auto;
  user-select: none;
  cursor: @cursor;
}
.list-wrapper /deep/ .list-item {
  transition: background-color 0.2s;
  border-radius: @radius;
  display: flex;
  align-items: @align-items;
  height: 35px;
  padding: 0 4px;
  color: @color;
  font-size: 13px;
  cursor: pointer;
  &:hover,
  &:active {
    background: @highlight-bg;
    color: @highlight-color;
  }
  .column-icon {
    margin: 0 5px;
  }
  &:hover .column-label {
    border-bottom: @decoration;
  }
  .column-fit {
    flex: 1;
  }
  .column-addon {
    visibility: hidden;
    margin-right: 3px;
    &:hover {
      opacity: 0.65;
    }
  }
  &:hover .column-addon {
    visibility: visible;
  }
  .checkbox {
    width: 1em;
    height: 1em;
  }
}
</style>
