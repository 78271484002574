/* eslint-disable no-unused-vars */
import debounce from "lodash/debounce";
import InputSelect from "../../context-input-select";

export function placeholder(h) {
  return <span class="value-from"></span>;
}

export function inputFrom(h, condition) {
  return (
    <span class="value-from">
      <a-input
        value={condition.fromValue}
        placeholder="输入转换前的值"
        onInput={evt => (condition.fromValue = evt.target.value)}
      />
    </span>
  );
}

export function inputSelectFrom(h, condition, context, isNumber = true) {
  const filterValues = context.props.filterValues || [];
  function onSearch(keyword = "") {
    keyword = keyword ? keyword : condition.fromValue;
    const listener = context.listeners["on-search"];
    if (listener) listener({ keyword });
  }
  return (
    <span class="value-from">
      <InputSelect
        is-number={isNumber}
        get-popup-container={context.props.getPopupContainer}
        placeholder="输入转换前的值"
        value={condition.fromValue}
        filter-values={filterValues}
        onChange={value => (condition.fromValue = value)}
        onSearch={debounce(onSearch, 380)}
      />
    </span>
  );
}

/**
 * ⚠️ a-input 不开启 allow-clear 属性时，需要包裹一层容器
 * 否则 flex-grow 和 padding 会导致 placeholder 和 input 对不齐
 */
export function inputTo(h, condition) {
  return (
    <span class="value-to">
      <a-input
        value={condition.toValue}
        placeholder="输入转换后的值"
        onInput={evt => (condition.toValue = evt.target.value)}
      />
    </span>
  );
}

export function numberFrom(h, condition) {
  return (
    <span class="value-from">
      <a-input-number
        value={condition.fromValue}
        placeholder="输入转换前的值"
        style="width: 100%"
        onChange={value => (condition.fromValue = value)}
      />
    </span>
  );
}

export function numberTo(h, condition) {
  return (
    <span class="value-to">
      <a-input-number
        value={condition.toValue}
        placeholder="输入转换前的值"
        style="width: 100%"
        onChange={value => (condition.toValue = value)}
      />
    </span>
  );
}

export function numberRangeFrom(h, condition) {
  return (
    <span class="value-from">
      <a-input-group compact>
        <a-input-number
          value={condition.fromValueStart}
          onChange={value => (condition.fromValueStart = value)}
          placeholder="最小值"
          style="width: 50%"
        />
        <a-input-number
          value={condition.fromValueEnd}
          onChange={value => (condition.fromValueEnd = value)}
          placeholder="最大值"
          style="width: 50%"
        />
      </a-input-group>
    </span>
  );
}

export function icon(h) {
  return <i class="indicator ndl-icon-corner-up-right"></i>;
}
