import config from "../config";
import { Group } from "../model/group";

const cacheMap = new WeakMap();

/**
 * 计算工作表分组时，单元格的水平偏移
 * @param {Array} groups 工作表分组信息
 * @returns {Number}
 */
export function calcGroupOffset(groups = []) {
  const first = groups[0] || {};
  const level = first.maxGroupLevel || 0;
  return level * config.groupGutter;
}

/**
 * 根据分组和记录计算 body 高度
 * @param {Array} groups 工作表分组信息
 * @param {Array} data 工作记录
 * @returns {Number} 工作表主体部分高度
 */
export function calcGridViewheight(groups = [], data = []) {
  if (groups.length) return Group.heightOfGroups(groups);
  return data.length * config.cellHeight;
}

/**
 * 一个计算列宽的工具函数
 * @param {Array} columns 工作表列数组
 * @param {Boolean} useCache 使用缓存的计算结果
 * @return {Number}
 */
export function calcWidth(columns = [], useCache = true) {
  const cachedWidth = cacheMap.get(columns);
  if (useCache && cachedWidth) return cachedWidth;
  const defaultWidth = config.cellWidth;
  const width = columns.reduce((sum, item) => {
    item.columnLeft = sum;
    const columnWidth = item.columnWidth || defaultWidth;
    return sum + columnWidth;
  }, 0);
  cacheMap.set(columns, width);
  return width;
}

export default calcWidth;
