import { debounceByAnimationFrame as debounce } from "@/utils/lib";
import { findCell } from "../cell-editor/utils";
import clamp from "lodash/clamp";

const autoscroll = (function() {
  let handle = null;
  let delta = { deltaX: 0, deltaY: 0 };
  return {
    start(sheetRoot) {
      cancelAnimationFrame(handle);
      handle = requestAnimationFrame(function callback() {
        if (delta.deltaX) sheetRoot.onWheel(delta);
        handle = requestAnimationFrame(callback);
      });
    },
    update(bounding, evt) {
      const EDGE = 150;
      const boundLeft = bounding.left + EDGE;
      const boundRight = bounding.right - EDGE;
      const clientX = evt.clientX;
      if (clientX < boundLeft) {
        const diff = clamp(boundLeft - clientX, 2, EDGE);
        delta = { deltaX: -Math.pow(Math.log2(diff), 2), deltaY: 0 };
        return;
      }
      if (clientX > boundRight) {
        const diff = clamp(clientX - boundRight, 2, EDGE);
        delta = { deltaX: Math.pow(Math.log2(diff), 2), deltaY: 0 };
        return;
      }
      delta = { deltaX: 0, deltaY: 0 };
    },
    stop() {
      cancelAnimationFrame(handle);
      delta = { deltaX: 0, deltaY: 0 };
    }
  };
})();

const dragImage = (function() {
  const elm = document.createElement("div");
  Object.assign(elm.style, {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    background: "rgba(24, 144, 255, 0.83)",
    color: "#fff",
    fontSize: "12px",
    borderRadius: "24px",
    lineHeight: "24px",
    padding: "0 8px",
    minWidth: "120px",
    maxWidth: "200px",
    pointerEvents: "none",
    willChange: "left, top",
    transform: "translate(-50%, -50%)"
  });
  elm.className = "ndl-ellipsis";
  // show 标识是否处于显示状态，显示状态下调用 show() 会忽略
  let show = false;
  // 非 active 下调用 show / hide 会忽略
  let active = false;
  let initialCursor = document.body.style.cursor;
  return {
    active() {
      active = true;
    },
    deactive() {
      active = false;
      this.hide();
    },
    show(cell) {
      if (show || !active) return;
      show = true;
      const cellLabel = cell.querySelector(".cell-label");
      if (cellLabel) elm.innerText = cellLabel.innerText;
      document.body.append(elm);
      document.body.style.cursor = "grabbing";
    },
    update(left, top) {
      elm.style.left = left + "px";
      elm.style.top = top + "px";
    },
    hide() {
      show = false;
      elm.remove();
      document.body.style.cursor = initialCursor;
    }
  };
})();

/**
 * <cell-header /> 点击时偶尔会触发拖拽功能。
 * 此工具用于打印相关事件信息，辅助线上调试。
 *
 * @date 2022-04
 */
const spreadSheetDebug = {
  enable: false,
  log(...params) {
    if (spreadSheetDebug.enable) {
      // 避免打包时 webpack plugin 移除 console 语句
      window["console"].log(...params);
    }
  }
};

window.spreadSheetDebug = spreadSheetDebug;

export default {
  inject: ["sheetRoot"],
  methods: {
    onDragMouseDown(mouseDownEvt) {
      // 防止触发文本选择
      mouseDownEvt.preventDefault();
      // eslint-disable-next-line prettier/prettier
      const isResizeTarget = mouseDownEvt.target.matches(".cell-header .cell-resizer");
      spreadSheetDebug.log(
        "[mouse down]",
        `(${mouseDownEvt.clientX}, ${mouseDownEvt.clientY})`,
        "is resize:",
        isResizeTarget,
        mouseDownEvt
      );
      if (isResizeTarget) {
        return;
      }
      const headerCell = findCell(mouseDownEvt.target, ".cell.cell-header");
      if (!headerCell) {
        return;
      }
      let fromIndex = Number(headerCell.dataset.col);
      let toIndex = fromIndex;
      // 移出单元格外即取消移动
      let canceled = false;
      // 表格容器边界
      const sheetRoot = this.sheetRoot;
      // this.$emit("resize") 会有 bug，需要直接调用 sheetHeader 的方法移动指示线
      // 因为自动滚动时，this 所在的组件实例可能被销毁
      const sheetHeader = this.$parent;
      const containerBounding = sheetRoot.$el.getBoundingClientRect();
      // 自动滚动功能就绪
      autoscroll.start(sheetRoot);
      // 拖拽图标就绪
      dragImage.active();
      const onMouseUp = mouseUpEvt => {
        spreadSheetDebug.log(
          "[mouse up]",
          `(${mouseUpEvt.clientX}, ${mouseUpEvt.clientY})`,
          mouseUpEvt
        );
        // 停用自动滚动
        autoscroll.stop();
        // 停用拖拽图标
        dragImage.deactive();
        // 延迟 10ms 更新 dragging 状态，避免触发 cell-header 的 click 事件
        setTimeout(() => {
          this.dragging = false;
        }, 10);
        // 隐藏指示线
        sheetHeader.onCellResize({ bounding: {}, diff: 0, show: false });
        // 往后移动时位置减 1，至于为什么要减就自己琢磨
        if (toIndex > fromIndex) toIndex -= 1;
        if (!canceled && fromIndex !== toIndex) {
          const orders = sheetRoot.columns.map(item => item.columnId);
          orders.splice(toIndex, 0, orders.splice(fromIndex, 1)[0]);
          sheetRoot.$emit("move", orders, fromIndex, toIndex);
        }
        document.removeEventListener("mouseup", onMouseUp, true);
        document.removeEventListener("mousemove", onMouseMove, true);
      };
      const onMouseMove = debounce(mouseMoveEvt => {
        spreadSheetDebug.log(
          "[mouse move]",
          `(${mouseMoveEvt.clientX}, ${mouseMoveEvt.clientY})`,
          mouseMoveEvt
        );
        // 水平移动小于 4px 认为是 click 时手抖👋，避免想 click 时触发 drag
        if (Math.abs(mouseDownEvt.clientX - mouseMoveEvt.clientX) < 4) {
          return;
        }
        this.dragging = true;
        // 防止触发文本选择
        mouseMoveEvt.preventDefault();
        canceled = false;
        // 自动滚动
        autoscroll.update(containerBounding, mouseMoveEvt);
        // 移出单元格外即取消移动，因此隐藏指示线
        const cell = findCell(mouseMoveEvt.target, ".cell[data-col-id]");
        if (!cell) {
          canceled = true;
          dragImage.hide();
          sheetHeader.onCellResize({ bounding: {}, diff: 0, show: false });
          return;
        }
        dragImage.show(cell);
        // update drag image position
        dragImage.update(mouseMoveEvt.clientX, mouseMoveEvt.clientY);
        // 更新
        toIndex = Number(cell.dataset.col);
        const cellBounding = cell.getBoundingClientRect();
        // 显示指示线
        const bounding = { right: cellBounding.left };
        // 根据鼠标位置与单元格左右两侧的距离判断是移动到其前面还是后面
        const distanceLeft = mouseMoveEvt.clientX - cellBounding.left;
        const distanceRight = cellBounding.right - mouseMoveEvt.clientX;
        if (distanceLeft > distanceRight) {
          bounding.right = cellBounding.right;
          toIndex += 1;
        }
        sheetHeader.onCellResize({ bounding, diff: 0 });
      });
      document.addEventListener("mouseup", onMouseUp, true);
      document.addEventListener("mousemove", onMouseMove, true);
    }
  }
};
