<template>
  <a-dropdown
    v-model="dropdownVisible"
    :trigger="['click']"
    :get-popup-container="el => el.parentNode"
    :disabled="disabled"
    placement="bottomCenter"
  >
    <div class="dropdown-rel">
      <i class="column-icon" :class="value | icon"></i>
      <span class="label ndl-ellipsis">{{ value | label }}</span>
      <a-icon type="down" class="arrow" />
    </div>
    <!-- .ant-dropdown-menu 可以获得 box-shadow 等多重 buff，免去写 css 的伤害 -->
    <div
      v-fit-width="$el"
      slot="overlay"
      class="ant-dropdown-menu cell-wrapper"
    >
      <div
        v-for="item in types"
        :key="item"
        class="cell"
        :class="{ selected: item === value }"
        @click="onClick(item)"
      >
        <i :class="item | icon"></i>
        <span>{{ item | label }}</span>
      </div>
      <!-- justify fix -->
      <div
        v-for="item in countPlaceholder"
        :key="item"
        class="cell justify-fix"
        :class="{ selected: item === value }"
        @click="onClick(item)"
      ></div>
    </div>
  </a-dropdown>
</template>

<script>
import { Icon, Types, Label } from "@/constant/column-types";

export default {
  props: {
    value: String,
    disabled: Boolean
  },
  directives: {
    // antd dropdown 组件 slot 的宽度有可能会超出 rel 的宽度
    // 且无法调整，需要通过指令设置 slot 的宽度
    fitWidth: {
      inserted(el, { value }) {
        el.style.width = value.offsetWidth + "px";
      },
      update(el, { value }) {
        el.style.width = value.offsetWidth + "px";
      }
    }
  },
  data() {
    return {
      dropdownVisible: false,
      types: Object.keys(Types).filter(item => item !== Types.function)
    };
  },
  computed: {
    countPlaceholder() {
      const MAX = 3;
      return MAX - (this.types.length % MAX);
    }
  },
  filters: {
    icon(type) {
      return Icon[type] || Icon.text;
    },
    label(type) {
      return Label[type] || Label[Types.text];
    }
  },
  created() {
    if (!this.value) this.$emit("input", Types.text);
  },
  methods: {
    onClick(type) {
      this.$emit("input", type);
      this.dropdownVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.dropdown-rel {
  background: #fff;
  height: @input-height-base;
  border-radius: @border-radius-base;
  border: solid 1px @border-color-base;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.2s;
  position: relative;
  padding-left: 8px;
  padding-right: 24px;
  &:hover,
  &.ant-dropdown-open {
    border-color: @blue-5;
  }
  &.ant-dropdown-open {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 20%);
  }
  .arrow {
    color: rgba(0, 0, 0, 0.25);
    display: block;
    position: absolute;
    right: 11px;
    transition: transform 0.3s;
    transform: scale(1) rotate(0);
  }
  &.ant-dropdown-open .arrow {
    transform: rotate(-180deg);
  }
}
.ant-dropdown-content /deep/ .column-icon,
.column-icon {
  margin-right: 5px;
  color: #595959;
}
@cell-gutter: 8px;
.cell-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: @cell-gutter;
  .cell {
    width: 55px;
    height: 55px;
    margin: @cell-gutter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px #ebebeb;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    i {
      font-size: 18px;
      margin-bottom: 4px;
      color: rgba(0, 0, 0, 0.65);
    }
    span {
      font-size: 12px;
      color: @text-color-secondary;
    }
    &:hover {
      background: #f5f5f5;
    }
    &.selected {
      background: @blue-1;
      border-color: @blue-5;
    }
  }
  .justify-fix {
    height: 0;
    overflow: hidden;
    border: none;
    margin: 0 @cell-gutter;
  }
}
</style>
