<template>
  <div>
    <FormLabel class="ndl-flex ndl-flex--middle">
      <span class="ndl-flex-item--grow">
        <span>{{ item.label }}</span>
        <span
          v-if="item.required"
          class="ndl-color-red"
          style="display: inline-block; transform: scale(1.3) translate(2px, 1px)"
        >
          *
        </span>
      </span>
      <span v-if="showSwitcher">自定义输入</span>
      <a-switch
        v-if="showSwitcher"
        v-model="item.isCustom"
        size="small"
        style="transform: scale(0.85); margin-left: 2px"
      />
    </FormLabel>
    <slot></slot>
  </div>
</template>

<script>
import FormLabel from "../../form-label";

export default {
  components: { FormLabel },
  props: {
    item: {
      type: Object,
      required: true
    },
    showSwitcher: {
      type: Boolean,
      default: true
    }
  }
};
</script>
