// utils
import clone from "lodash/cloneDeep";
import Column from "@/model/column";
import { isEqual } from "@/utils/lib";
import getColumnOptions from "@/views/datamodel/utils/column-options";
import { Modal } from "ant-design-vue";

// actions
import workspace from "@/views/datamodel/models/workspace";
import Actions from "@/constant/datasheet-actions";
import Condition from "@/views/datamodel/models/condition";

// context
import showContextmenu from "@/components/contextmenu";
import showContextEditColumn from "@/views/datamodel/components/context-edit-column";
import showContextEditExpression from "@/views/datamodel/components/context-edit-expression";
import showContextCaseWhen from "@/views/datamodel/components/context-case-when";

async function showCaseWhenEditor(column, source) {
  const transform = new Condition.CaseWhen(column && column.transform);
  const keepState = clone(transform);
  await showContextCaseWhen({ column, source, transform }).wait();
  // 没有任何操作，不用发请求
  if (isEqual(keepState, transform)) return;
  const action = Actions.casewhen({ ...column, transform });
  workspace.currentDatasheet.send(action);
}

export default async function(column, evt, cellElement) {
  const options = getColumnOptions(column);
  // 启用 SQL 查询功能后，只开放 `rename` 菜单
  const query = workspace.currentDatasheet.query;
  if (query && query.enable) {
    for (const item of options) {
      item.disabled = item.key !== "rename";
    }
  }
  const contextConfig = { source: cellElement, inverse: { y: false, x: true } };
  const option = await showContextmenu({ options }, contextConfig);
  switch (option.key) {
    case "rename":
      showContextEditColumn(column, cellElement, attrs => {
        const action = Actions.alter({
          ...column,
          ...attrs,
          // 后端需要 isColumnTypeModified 标识做一些判断
          isColumnTypeModified: column.columnType != attrs.columnType
        });
        workspace.currentDatasheet.send(action);
      });
      break;
    case "copy":
      workspace.currentDatasheet.send(Actions.clone(column));
      break;
    case "hide":
      /**
       * @todo 隐藏列功能暂未实现
       */
      column.visible = false;
      break;
    case "delete":
      Modal.confirm({
        title: "删除列",
        okType: "danger",
        content: `确认删除列「${column.columnName}」?`,
        onOk: () => workspace.currentDatasheet.send(Actions.remove(column))
      });
      break;
    // 日期格式化，option.checked 表示选择的菜单已经处于选中状态
    case "format":
      if (!option.checked) {
        const cloneColumn = Column.from(column);
        cloneColumn.columnOptions.dayFmt = option.value;
        const action = Actions.alter(cloneColumn);
        workspace.currentDatasheet.send(action);
      }
      break;
    // 聚合操作
    case "agg":
      if (!option.checked) {
        const action = Actions.agg({
          columnId: column.columnId,
          aggType: option.value
        });
        workspace.currentDatasheet.send(action);
      }
      break;
    // 条件转换
    case "casewhen":
      showCaseWhenEditor(column, cellElement);
      break;
    // 表达式
    case "exp":
      showContextEditExpression(
        {
          column,
          func: option.value,
          source: cellElement,
          columns: workspace.currentDatasheet.columns
        },
        expression => {
          const action = Actions.trans({ ...column, expression });
          workspace.currentDatasheet.send(action);
        }
      );
      break;
    // 同比
    case "yoy":
      workspace.currentDatasheet.send(
        Actions.yearOnYear({ column, period: option.value })
      );
      break;
    // 环比
    case "mom":
      workspace.currentDatasheet.send(Actions.monthOnMonth({ column }));
      break;
  }
}
