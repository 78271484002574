import * as Model from "./model";
import { Types } from "@/constant/column-types";

const group = new Model.Group({ label: "行处理" });

group.add({
  name: "ROW",
  returns: Types.number,
  readme: "当前行的行号",
  usage: "ROW()",
  example: ["ROW()\n", "=> 1"].join("")
});

export default group;
