import * as Model from "./model";
import groupRefColumn from "./group-refcolumn";
import groupNumber from "./group-number";
import groupText from "./group-text";
import groupDate from "./group-date";
import groupLogical from "./group-logical";
import groupIndex from "./group-row";

const doc = new Model.Doc();

doc.add(groupRefColumn);
doc.add(groupIndex);
doc.add(groupNumber);
doc.add(groupText);
doc.add(groupDate);
doc.add(groupLogical);

export default doc;
