import contextify from "@/components/contextify";

export default function(column, source, onConfirm) {
  const opts = {
    column,
    source,
    onConfirm,
    inverse: { y: false, x: true },
    style: { overflow: "visible" }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/EditColumn"),
    opts
  );
}
