import * as Model from "./model";
import { Types } from "@/constant/column-types";

const group = new Model.Group({ label: "数值处理" });

group.add({
  name: "CEIL",
  returns: Types.number,
  snippet: "CEIL(${1:number})$0",
  readme: "向上取整",
  usage: "CEIL(number)",
  example: [
    "CEIL(-42.8)\n",
    "=> -42\n\n",
    "CEIL(42.8)\n",
    "=> 43\n\n",
    "CEIL({A})"
  ].join("")
});

group.add({
  name: "FLOOR",
  returns: Types.number,
  snippet: "FLOOR(${1:number})$0",
  readme: "向下取整",
  usage: "FLOOR(number)",
  example: [
    "CEIL(-42.8)\n",
    "=> -43\n\n",
    "CEIL(42.8)\n",
    "=> 42\n\n",
    "CEIL({A})"
  ].join("")
});

export default group;
