import * as Model from "./model";
import { Types } from "@/constant/column-types";

const group = new Model.Group({ label: "文本处理" });

group.add({
  name: "REPLACE",
  returns: Types.text,
  snippet: "REPLACE(${1:string}, ${2:old_string}, ${3:new_string})$0",
  readme: "将【string】中的【old_string】替换为【new_string】",
  usage: "REPLACE(string, old_string, new_string)",
  example: [
    "REPLACE('Thomas', 'om', 'hello')\n",
    "=> Thhelloas\n\n",
    "REPLACE({A}, 'hello', 'world')"
  ].join("")
});

group.add({
  name: "SUBSTRING",
  returns: Types.text,
  snippet: "SUBSTRING(${1:string}, ${2:begin}, ${3:length})$0",
  readme: "截取子字符串",
  usage: "SUBSTRING(string, begin[, length])",
  example: ["SUBSTRING('Thomas', 2, 3)\n", "=> hom"].join("")
});

export default group;
