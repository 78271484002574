<template>
  <div class="arg-wrapper">
    <ArgItem
      v-for="(item, index) in args"
      :key="index"
      :item="item"
      class="ndl-margin-top"
    >
      <!-- 设置 key 避免组件复用导致旧输入未清空 -->
      <a-input
        v-if="item.isCustom"
        v-model="item.customValue"
        :key="func"
        placeholder="请输入参数"
      />
      <SelectColumn
        v-else
        v-model="item.columnId"
        block
        :columns="columns"
        :get-popup-container="getPopupContainer"
        placeholder="请选择列"
        style="width: 100%"
      />
    </ArgItem>
  </div>
</template>

<script>
import mixin from "./mixin-arg-editor";

export default {
  name: "ArgEditorCommon",
  mixins: [mixin]
};
</script>
