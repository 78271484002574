<template>
  <div class="group">
    <div class="header">{{ group.label }}</div>
    <div
      v-for="(item, index) in group.children"
      :key="index"
      :title="item.name"
      :data-id="item.id"
      :class="[
        'item',
        { focused: focusedFormula && focusedFormula.id === item.id }
      ]"
      @mouseenter="$emit('focus', item)"
      @mousedown.prevent
      @click="$emit('select', item)"
    >
      <i :class="[iconType(item.returns), 'icon']"></i>
      <span class="label ndl-ellipsis">{{ item.name }}</span>
      <span v-if="item.alias" class="append">{{ item.alias }}</span>
    </div>
  </div>
</template>

<script>
import { Icon } from "@/constant/column-types";

export default {
  props: {
    /**
     * @type {import("./doc/model").Group}
     */
    group: Object,
    focusedFormula: Object
  },
  methods: {
    iconType(type) {
      return Icon[type];
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.group {
  font-size: 13px;
  margin: 12px 0;
  .header {
    color: #bfbfbf;
    padding: 0 12px;
  }
  .item {
    display: flex;
    align-items: center;
    height: 28px;
    padding-left: 12px;
    margin-top: 6px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover,
    &.focused {
      background: @blue-1;
    }
  }
  .icon {
    width: 20px;
    flex-shrink: 0;
  }
  .label {
    flex: 1 1 0;
  }
  .append {
    flex-shrink: 0;
    padding: 0 4px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
