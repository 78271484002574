import SelectColumn from "../../select-column";
import ArgItem from "./ArgItem";

export default {
  components: { SelectColumn, ArgItem },
  props: {
    args: Array,
    func: String,
    columns: Array,
    getPopupContainer: Function
  },
  data() {
    return {
      timeUnitList: [
        {
          label: "年份",
          value: "YEAR"
        },
        {
          label: "季度",
          value: "QUARTER"
        },
        {
          label: "月份",
          value: "MONTH"
        },
        {
          label: "周次",
          value: "WEEK"
        },
        {
          label: "日",
          value: "DAY"
        },
        {
          label: "时",
          value: "HOUR"
        },
        {
          label: "分",
          value: "MINUTE"
        }
      ]
    };
  }
};
