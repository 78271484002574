<template>
  <!-- @mousedown.stop 防止关闭其它 context -->
  <div class="palette non-square" @mousedown.stop>
    <span
      v-for="(item, index) in colors"
      :key="index"
      :style="`background-color: ${item[0]}; color: ${item[1]}`"
      :class="['unit', { active: data.color === index }]"
      @click="onSelect(item, index)"
    ></span>
  </div>
</template>

<script>
import { CellColors } from "@/components/spread-sheet";

export default {
  props: {
    data: Object
  },
  computed: {
    colors: () => CellColors
  },
  methods: {
    onSelect(item, index) {
      if (this.data) this.data.color = index;
      this.$parent.hide();
    }
  }
};
</script>

<style lang="less" scoped>
@size: 24px;
@count-per-row: 10;

.palette {
  background: url(~@/assets/images/bg_1.png) top right / cover no-repeat;
  display: flex;
  flex-wrap: wrap;
  width: @count-per-row * @size;
  padding: 10px 14px;
  box-sizing: content-box;
  .unit {
    width: @size;
    height: @size;
    border-radius: 50%;
    border: solid 2px transparent;
    padding: 2px;
    background-clip: content-box;
    margin: 2px 0;
    cursor: pointer;
  }
  .unit:hover {
    border-color: #91d5ff;
  }
  .unit.active {
    border-color: #40a9ff;
  }
  &.square {
    padding: 16px;
  }
  &.square .unit {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
  }
  &.square .unit:hover {
    padding: 2px;
  }
}
</style>
