<template>
  <!-- 根元素需要 @click.stop 防止点击编辑器时触发单元格编辑 -->
  <div
    :class="['cell-editor', { mounted: !!cellMeta }]"
    :data-type="columnType"
    @click.stop
    @keydown.stop
  >
    <component
      :is="editor"
      :key="editorKey"
      :column="column"
      :data-type="columnType"
      :initial-value="initialValue"
      :visual-value="visualValue"
      :focused.sync="focused"
      class="editor"
      v-model="currentValue"
      @confirm="onConfirm(0, 1)"
      @prev="onConfirm(-1, 0)"
      @next="onConfirm(1, 0)"
      @cancel="onCancel()"
    />
  </div>
</template>

<script>
import clipboard from "copy-to-clipboard";
import Types from "../define/column-types";
import KeyCode from "@/utils/keycode";
import * as Utils from "./utils";

import EditorText from "./EditorText";
// import EditorFunction from "./EditorFunction";
import EditorRadio from "./EditorRadio";

export default {
  props: {
    cellSelector: {
      type: String,
      default: ".cell.cell-body"
    },
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 编辑前的单元格真实值
      initialValue: null,
      // 编辑完的单元格真实值
      currentValue: null,
      // 单元格显示值（以公式列为例，显示值是公式计算结果，真实值是公式，其它类型列两个值通常相同）
      visualValue: null,
      // 编辑的单元格对应的 DOM Element
      cellMeta: null,
      focused: false,
      // 用于强制重新渲染，移动单元格时要重设状态
      editorKey: 0
    };
  },
  computed: {
    column() {
      const meta = this.cellMeta || {};
      return this.columns.find(item => item.columnId === meta.colId) || null;
    },
    columnType() {
      const column = this.column || {};
      return column.columnType || null;
    },
    editor() {
      switch (this.columnType) {
        // case Types.function:
        //   return EditorFunction;
        case Types.radio:
          return EditorRadio;
        default:
          return EditorText;
      }
    }
  },
  created() {
    document.addEventListener("keydown", this.onGlobalKeydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onGlobalKeydown);
  },
  methods: {
    focus(cell) {
      this.focused = false;
      if (cell instanceof HTMLElement && cell.matches(this.cellSelector)) {
        this.cellMeta = cell.dataset;
        this.initialValue = Utils.getCellValue(cell.dataset, this, false);
        this.currentValue = this.initialValue;
        this.visualValue = Utils.getCellValue(cell.dataset, this, true);
        cell.append(this.$el);
        this.editorKey++;
      } else {
        this.cellMeta = null;
        this.initialValue = null;
        this.currentValue = null;
        this.visualValue = null;
        this.$el.remove();
      }
    },
    blur() {
      this.focus(null);
    },
    onGlobalKeydown(evt) {
      if (!document.contains(this.$el)) return;
      if (KeyCode.isTab(evt)) {
        this.moveCellEditor(evt.shiftKey ? -1 : 1, 0);
        evt.preventDefault();
        return;
      }
      if (KeyCode.isEnter(evt)) {
        this.focused = true;
        return;
      }
      // 复制单元格，提示本应上层组件提示 message，这里为了简单先实现需求
      if (KeyCode.isCopy(evt)) {
        clipboard(this.visualValue);
        return this.$message.success("已复制到剪贴板");
      }
      if (KeyCode.isChar(evt)) {
        this.focused = true;
        this.currentValue = evt.key;
        return;
      }
      switch (evt.keyCode) {
        case KeyCode.UP:
          this.moveCellEditor(0, -1);
          break;
        case KeyCode.DOWN:
          this.moveCellEditor(0, 1);
          break;
        case KeyCode.LEFT:
          this.moveCellEditor(-1, 0);
          break;
        case KeyCode.RIGHT:
          this.moveCellEditor(1, 0);
          break;
      }
    },
    moveCellEditor(x = 0, y = 0) {
      const meta = this.cellMeta;
      if (!meta) return;
      const columnIndex = Number(meta.col) + x;
      const rowIndex = Number(meta.row) + y;
      if (columnIndex < 0 || columnIndex >= this.columns.length) return;
      if (rowIndex < 0 || rowIndex >= this.data.length) return;
      this.$emit("move", columnIndex, rowIndex, x, y);
    },
    saveCellValue() {
      const value = this.currentValue;
      if (this.initialValue === value) return;
      const meta = this.cellMeta || {};
      const payload = {
        ...meta,
        value,
        column: this.column,
        record: this.data[meta.row] || {}
      };
      this.$emit("confirm", value, payload);
    },
    onConfirm(x = 0, y = 0) {
      this.focused = false;
      this.saveCellValue();
      this.moveCellEditor(x, y);
    },
    onCancel() {
      this.focused = false;
      this.currentValue = this.initialValue;
    }
  }
};
</script>

<style src="./style.less" lang="less" scoped></style>
