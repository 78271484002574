<template>
  <div class="readme">
    <div class="header">{{ renderFm.name }}</div>
    <div class="info">{{ renderFm.readme }}</div>
    <div class="label">用法</div>
    <Codeblock wrapline :code="renderFm.usage" />
    <div class="label">示例</div>
    <Codeblock wrapline :code="renderFm.example" />
  </div>
</template>

<script>
import { Formula } from "./doc/model";
import Codeblock from "./Codeblock";

export default {
  components: { Codeblock },
  props: {
    formula: Formula
  },
  computed: {
    renderFm() {
      if (this.formula) {
        return this.formula;
      }
      return new Formula({
        name: "公式样例",
        readme: "填写变量、运算符和函数来组成公式进行运算",
        usage: [
          "# 引用视图列",
          "{A}\n",
          "# 使用运算符",
          "3 * 6\n",
          "# 使用函数",
          "CEIL({A})\n",
          "# 使用IF语句",
          "IF(条件, 'Yes', 'No')"
        ].join("\n"),
        example: [
          "{A} * {B}",
          `{A} & "-" & {B}`,
          "FLOOR({C})",
          `IF({D} > 18, "成年人", "未成年")`
        ].join("\n\n")
      });
    }
  }
};
</script>

<style lang="less" scoped>
.readme {
  padding: 12px;
  .header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .info {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
    white-space: pre-wrap;
  }
  .label {
    margin-top: 2em;
    margin-bottom: 12px;
    line-height: 1;
    padding-left: 4px;
    border-left: solid 3px #1890ff;
  }
}
</style>
