import {
  debounceByAnimationFrame as debounce,
  throttleByAnimationFrame as throttle
} from "@/utils/lib";

/**
 * Index 中用于滚动同步、鼠标滚轮事件处理、自定义滚动条的东西
 * @todo 目前通过更新视图的 scrollTop 实现滚动，性能可能差于 translate3d 实现
 */
export default {
  data() {
    return {
      hasScroll: false
    };
  },
  methods: {
    _changePosition(x, y) {
      const pos = this.$refs.paneBody.getScrollPosition();
      pos.left += x;
      pos.top += y;
      this.syncPaneScrollPosition(pos);
      this.updateScrollbar(pos);
    },
    _animationStart() {
      this._timeoutAnimation = window.requestAnimationFrame(() => {
        if (!this._toucheInfo) return;
        const baseHz = 600;
        const stop = 0.1;
        const { diffX, diffY, _count = baseHz } = this._toucheInfo;
        if (
          diffX <= stop &&
          diffX >= -stop &&
          diffY <= stop &&
          diffY >= -stop
        ) {
          return;
        }
        const ratio = _count / baseHz;
        this._toucheInfo._count = _count - 1 / ratio;
        this._toucheInfo.diffX = diffX * ratio;
        this._toucheInfo.diffY = diffY * ratio;
        this._changePosition(-diffX, -diffY);
        this._animationStart();
      });
    },
    /**
     * 鼠标滚轮事件处理
     */
    onWheel: throttle(function(evt) {
      const { deltaX = 0, deltaY = 0 } = evt;
      this._changePosition(deltaX, deltaY);

      if (this._timeoutAnimation) cancelAnimationFrame(this._timeoutAnimation);
      const baseAnRatio = 0.06;
      this._toucheInfo = {
        clientX: 0,
        clientY: 0,
        diffX: -deltaX * baseAnRatio,
        diffY: -deltaY * baseAnRatio
      };
      this._animationStart();
    }),
    /**
     * 触摸移动事件处理
     */
    onTouchMove: throttle(function(evt) {
      if (!evt.touches || !evt.touches.length) return;

      const toucheInfo = evt.touches[0];
      if (!this._toucheInfo) {
        this._toucheInfo = toucheInfo;
      }
      const diffX = Math.ceil(toucheInfo.clientX - this._toucheInfo.clientX);
      const diffY = Math.ceil(toucheInfo.clientY - this._toucheInfo.clientY);
      toucheInfo.diffX = diffX;
      toucheInfo.diffY = diffY;
      this._toucheInfo = toucheInfo;
      if (Math.abs(diffX) > 50 || Math.abs(diffY) > 50) return;

      this._changePosition(-diffX * 1.2, -diffY * 1.2);
    }),
    /**
     * 触摸移动结束事件处理（会继续移动一下）
     */
    onTouchEnd() {
      this._animationStart();
    },
    /**
     * 同步各 pane 的滚动位置
     */
    syncPaneScrollPosition(pos, updateViewport = true) {
      const paneHeader = this.$refs.paneHeader;
      const paneBody = this.$refs.paneBody;
      const paneFooter = this.$refs.paneFooter;
      paneHeader.scrollTo(pos);
      paneBody.scrollTo(pos);
      paneFooter.scrollTo(pos);
      // 来自 mixin-viewport 的方法
      if (updateViewport) this.updateViewportContent(pos);
    },
    /**
     * 根据当前表格滚动位置计算 scrollbar 的位置
     */
    updateScrollbar: debounce(function(pos) {
      const elmX = this.$refs.scrollbarX;
      const elmY = this.$refs.scrollbarY;
      // container sizes
      const { offsetHeight: ctHeight = 0, offsetWidth: ctWidth = 0 } = this.$el;
      // scroll values
      const {
        scrollLeft = 0,
        scrollTop = 0,
        scrollWidth = 0,
        scrollHeight = 0,
        offsetHeight = 0,
        offsetWidth = 0
      } = pos;
      // calc scroll thumb
      const MIN_THUMB_SIZE = 50;
      const widthRatio = offsetWidth / scrollWidth;
      const heightRatio = offsetHeight / scrollHeight;
      const leftRatio = scrollLeft / (scrollWidth - offsetWidth);
      const topRatio = scrollTop / (scrollHeight - offsetHeight);
      const thumbWidth = Math.max(MIN_THUMB_SIZE, widthRatio * ctWidth);
      const thumbHeight = Math.max(MIN_THUMB_SIZE, heightRatio * ctHeight);
      const thumbLeft = leftRatio * (ctWidth - thumbWidth);
      const thumbTop = topRatio * (ctHeight - thumbHeight);
      elmX.setAttribute("remain", ctWidth - thumbWidth);
      elmX.setAttribute("rebase", scrollWidth - offsetWidth);
      elmY.setAttribute("remain", ctHeight - thumbHeight);
      elmY.setAttribute("rebase", scrollHeight - offsetHeight);
      Object.assign(elmX.style, {
        display: offsetWidth === scrollWidth ? "none" : "block",
        width: thumbWidth + "px",
        left: thumbLeft + "px"
      });
      Object.assign(elmY.style, {
        display: offsetHeight === scrollHeight ? "none" : "block",
        height: thumbHeight + "px",
        top: thumbTop + "px"
      });
    }),
    /**
     * 拖拽自定义滚动条时，更新滚动条位置、同步表格 pane 滚动
     */
    dragScrollbar(ref, evt) {
      const elm = this.$refs[ref];
      const remain = elm.getAttribute("remain") - 0;
      const rebase = elm.getAttribute("rebase") - 0;
      const pos = this.$refs.paneBody.getScrollPosition();
      let evtProp = "clientX";
      let styleProp = "left";
      let posProp = "left";
      let offsetValue = elm.offsetLeft;
      if (ref !== "scrollbarX") {
        evtProp = "clientY";
        styleProp = "top";
        posProp = "top";
        offsetValue = elm.offsetTop;
      }
      let prev = evt[evtProp];
      const onMouseMove = debounce(moveEvt => {
        const diff = Math.min(offsetValue + moveEvt[evtProp] - prev, remain);
        offsetValue = Math.max(0, diff);
        prev = moveEvt[evtProp];
        elm.style[styleProp] = offsetValue + "px";
        const ratio = offsetValue / remain;
        pos[posProp] = ratio * rebase;
        pos.scrollLeft = pos.left;
        pos.scrollTop = pos.top;
        this.syncPaneScrollPosition(pos);
      });
      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    /**
     * body pane scroll 时，同步其它 pane 和滚动条位置
     */
    onBodyScroll(pos) {
      this.syncPaneScrollPosition(pos);
      this.updateScrollbar(pos);
    }
  }
};
