import { Icon } from "@/constant/column-types";
import Checkbox from "@/components/checkbox";

export default {
  name: "ColumnItem",
  functional: true,
  render(h, context) {
    const { source = {}, index = 0 } = context.props || {};
    function onClick() {
      const onSelect = context.props.onSelect;
      if (typeof onSelect === "function") {
        onSelect.call(null, source, context);
      } else {
        source.visible = !source.visible;
      }
    }
    return (
      <div class="list-item" title={source.columnName} onClick={onClick}>
        <i class={["column-icon", Icon[source.columnType]]}></i>
        <span class="column-label ndl-ellipsis" data-index={index}>
          {source.columnName}
        </span>
        <span class="column-fit"></span>
        {source.visible && context.props.showCheckbox ? (
          <Checkbox checked />
        ) : null}
      </div>
    );
  }
};
