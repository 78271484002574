import * as Render from "./utils-render";
import FilterTypes from "@/constant/filter-types";

export default {
  functional: true,
  render(h, context) {
    const condition = context.props.condition;
    let nodeFrom = Render.numberFrom(h, condition);
    switch (condition.operatorType) {
      case FilterTypes.EQUAL:
      case FilterTypes.NOT_EQUAL:
        nodeFrom = Render.inputSelectFrom(h, condition, context);
        break;
      case FilterTypes.BETWEEN:
      case FilterTypes.BETWEEN_NOT_LEFT:
      case FilterTypes.BETWEEN_NOT_RIGHT:
      case FilterTypes.BETWEEN_NOT_BOTH:
        nodeFrom = Render.numberRangeFrom(h, condition);
        break;
      case FilterTypes.NULL:
      case FilterTypes.NOT_NULL:
      case FilterTypes.ELSE:
        nodeFrom = Render.placeholder(h);
        break;
    }
    return [nodeFrom, Render.icon(h), Render.inputTo(h, condition)];
  }
};
