import { ContextOption } from "@/components/contextmenu";
import cloneDeep from "lodash/cloneDeep";
import Types from "@/constant/column-types";
import expressions, { findExpression } from "./column-expressions";

const GROUP = new ContextOption().$width("165px");

export const Define = {
  // group
  GROUP_EXP: GROUP.$icon("repeat")
    .$set("group", "exp")
    .$divider(),
  GROUP_AGG: GROUP.$icon("crosshair")
    .$set("group", "agg")
    .$label("聚合操作"),
  GROUP_FORMAT: GROUP.$icon("calendar")
    .$set("group", "format")
    .$label("日期格式"),
  // action
  ACTION_CANCEL: new ContextOption("agg", "取消")
    .$value(null)
    .$labelClass("ndl-color-red"),
  // case when
  // CASE_WHEN: new ContextOption("casewhen", "条件转换", "git-branch"),
  // agg types
  AGG_SUM: new ContextOption("agg", "分组求和").$value("sum"),
  AGG_COUNT: new ContextOption("agg", "记录数").$value("count"),
  AGG_MAX: new ContextOption("agg", "最大值").$value("max"),
  AGG_MIN: new ContextOption("agg", "最小值").$value("min"),
  AGG_AVG: new ContextOption("agg", "平均值").$value("avg"),
  AGG_DISTINCT: new ContextOption("agg", "唯一记录数").$value("distinct")
};

function parseExpression(arr) {
  if (!arr) return [];
  return arr.map(item => {
    const option = new ContextOption("exp", item.label);
    return option.$value(item.func);
  });
}

export const options = {
  // 文本列菜单
  [Types.text]: [
    Define.GROUP_AGG.$children([
      Define.ACTION_CANCEL,
      Define.AGG_COUNT,
      Define.AGG_MAX,
      Define.AGG_MIN,
      Define.AGG_DISTINCT,
      Define.AGG_SUM
    ]),
    // Define.CASE_WHEN,
    Define.GROUP_EXP.$label("文本转换").$children([
      Define.ACTION_CANCEL.$key("exp"),
      ...parseExpression(expressions[Types.text])
    ])
  ],
  // 数值列菜单
  [Types.number]: [
    Define.GROUP_AGG.$children([
      Define.ACTION_CANCEL,
      Define.AGG_SUM,
      Define.AGG_AVG,
      Define.AGG_MAX,
      Define.AGG_MIN,
      Define.AGG_COUNT,
      Define.AGG_DISTINCT
    ]),
    // Define.CASE_WHEN,
    Define.GROUP_EXP.$label("数值转换").$children([
      Define.ACTION_CANCEL.$key("exp"),
      ...parseExpression(expressions[Types.number])
    ])
    // new ContextOption("mom", "计算环比", "percent"),
    // GROUP.$label("计算同比")
    //   .$icon("percent")
    //   .$divider()
    //   .$children([
    //     new ContextOption("yoy", "按「年」计算").$value("year"),
    //     new ContextOption("yoy", "按「月」计算").$value("month"),
    //     new ContextOption("yoy", "按「周」计算").$value("week")
    //   ])
  ],
  // 日期列菜单
  [Types.date]: [
    Define.GROUP_FORMAT.$children([
      Define.ACTION_CANCEL.$key("format"),
      new ContextOption("format", "日期时间").$value("YYYY-MM-DD HH:mm:ss"),
      new ContextOption("format", "年-月-日").$value("YYYY-MM-DD"),
      new ContextOption("format", "年-周").$value("YYYY-w周"),
      new ContextOption("format", "年-月").$value("YYYY-MM"),
      new ContextOption("format", "年-季度").$value("YYYY-Q季"),
      new ContextOption("format", "年份").$value("YYYY")
    ]),
    Define.GROUP_AGG.$children([
      Define.ACTION_CANCEL,
      Define.AGG_MAX,
      Define.AGG_MIN,
      Define.AGG_COUNT,
      Define.AGG_DISTINCT
    ]),
    // Define.CASE_WHEN,
    Define.GROUP_EXP.$label("日期转换").$children([
      Define.ACTION_CANCEL.$key("exp"),
      ...parseExpression(expressions[Types.date])
    ])
  ],
  // JSON列
  [Types.json]: [],
  // 公式列
  [Types.function]: []
};

// JSON列、公式列只有文本列的聚合操作
options[Types.json] = [options[Types.text][0]];
options[Types.function] = [options[Types.text][0]];

/**
 * 根据列类型生成 contextmenu options
 * 并根据列操作如「聚合」、「转换」、「日期格式」
 * 判断是否需要显示取消选项、显示 checked 标记
 * @param {Object} column 工作表列对象
 * @returns {Array} 表头隐藏的上下文菜单项
 */
export default function(column) {
  /**
   * Step 0: 获取对应字段类型的特殊操作时，要深克隆 ⚠️
   */
  const typedOptions =
    cloneDeep(options[column.columnType] || options[Types.text]) || [];
  /**
   * Step 1: 处理「聚合操作」菜单，应该是所有类型的列都有的操作～需要设置选中状态
   */
  const aggGroup = typedOptions.find(item => item.group === "agg");
  if (aggGroup) {
    const aggType = column.aggType;
    const option = aggGroup.children.find(item => item.value === aggType);
    // 已设置聚合方式时，则标记该选项的 checked 状态，并保留取消选项
    if (option) option.checked = true;
    // 未设置聚合操作时，隐藏位于第一项的「取消」选项
    if (!aggType) aggGroup.children.shift();
  }

  /**
   * Step 2: 处理「xx转换」菜单，也是要设置选中状态滴
   * @mention expGroup 仅包含当前列类型可选的表达式
   * @mention 列类型动态变化后，已选择的表达式可能不在 expGroup 中
   * @mention 因此这里需要判断已选表达式是否有必要添加到 expGroup.children
   * @mention 是否显示「取消」选项的判断规则也区别于 aggGroup
   */
  const expGroup = typedOptions.find(item => item.group === "exp");
  if (expGroup) {
    const func = column.expression && column.expression.func;
    const option = expGroup.children.find(item => item.value === func);
    // 已设置表达式，则标记该选项的 checked 状态，并保留取消选项
    if (option) option.checked = true;
    // 未设置表达式时，隐藏第一个「取消」选项
    if (!func) expGroup.children.shift();
    // ⚠️ func 存在，但又没在当前列类型可选的表达式中找到 option
    // 说明列类型可能变化过，此时的 selectedExp 是其它列类型的表达式
    // 需要生成一个新的选项添加到 children 中，用来提示当前列应用的表达式
    const selectedExp = func && !option ? findExpression(func) : null;
    if (selectedExp) {
      const newOption = new ContextOption("exp", selectedExp.label)
        .$value(selectedExp.func)
        .$checked();
      expGroup.children.push(newOption);
    }
  }

  /**
   * Step 3: 处理「日期格式」菜单，应该是日期列特有的，也是要设置选中状态滴
   */
  const formatGroup = typedOptions.find(item => item.group === "format");
  if (formatGroup) {
    const currentFormat = column.columnOptions.dayFmt;
    const children = formatGroup.children || [];
    const option = children.find(item => item.value === currentFormat);
    // 当前格式在 children 中，则标记该选项 checked，并保留取消选项
    if (option) option.checked = true;
    // 未选择 children 中的日期格式时，隐藏位于第一项的「取消」选项
    else children.shift();
    // ⚠️ 不可直接判断 currentFormat 是否存在来决定是否隐藏「取消」选项
    // ⚠️ 因为 currentFormat 可能不在 formatGroup.children 中
    // ⚠️ 此时即使设置了日期格式，也不需要显示「取消」选项
    // ❌ if (!currentFormat) formatGroup.children.shift();
  }
  return [
    new ContextOption("rename", "编辑列名称/选项", "edit"),
    new ContextOption("copy", "复制列", "copy").$divider(),
    ...typedOptions,
    new ContextOption("delete", "删除列", "trash-2")
      .$iconClass("ndl-color-red")
      .$labelClass("ndl-color-red")
  ];
}
