import Config from "../config";
import isPlainObject from "lodash/isPlainObject";

export class Group {
  /**
   * @param {Object|String} param 参数1为对象时，剩余参数将会被忽略。或传入字符串作为 columnId
   * @param {any} rest[0] 分组值 value，默认为 null
   * @param {Object} rest[1] 分组 parent，默认为 null
   */
  constructor(param, ...rest) {
    // declare props
    this.columnId = null;
    this.columnName = null;
    this.value = null;
    this.expanded = true;
    this.parent = null;
    this.offsetTop = 0;
    this.offsetLeft = 0;
    this.startIndex = 0;
    this.endIndex = 0;
    this.groups = [];
    this.statistics = null;
    // set initial value
    let initial = null;
    if (isPlainObject(param)) {
      initial = param;
    } else {
      initial = {
        columnId: param || null,
        value: rest[0] || null,
        parent: rest[1] || null
      };
    }
    Object.assign(this, initial);
  }
  get groupLevel() {
    return this.parent ? this.parent.groupLevel + 1 : 0;
  }
  get visible() {
    let parent = this.parent;
    while (parent) {
      if (!parent.expanded) return false;
      parent = parent.parent;
    }
    return true;
  }
  get height() {
    return Group.heightOfGroup(this);
  }
  get maxGroupLevel() {
    let depth = this;
    while (depth && depth.groups.length) {
      depth = depth.groups[0];
    }
    return depth.groupLevel;
  }

  flatten() {
    return Group.flatten(this);
  }
  offsetTopOfCell(cellIndex) {
    const { offsetTop, startIndex } = this;
    return offsetTop + (cellIndex - startIndex) * Config.cellHeight;
  }
  /**
   * 展开 / 收起分组
   * @param  {Boolean} params[0] 展开或收起，默认切换当前状态
   * @param  {Boolean} params[1] 是否递归展开/收起子分组
   * @return {Boolean} 返回切换后的状态
   */
  toggle(...params) {
    const value = params.length ? !!params[0] : !this.expanded;
    const deep = !!params[1];
    this.expanded = value;
    if (!deep) return value;
    for (const subgroup of this.groups || []) {
      if (subgroup && subgroup.toggle) subgroup.toggle(value, deep);
    }
    return value;
  }

  static heightOfGroup(group = {}) {
    const headerHeight = Config.groupCellHeight;
    // 折叠状态，分组高度等于分组头高度
    if (!group.expanded) return headerHeight;
    // 展开状态，且存在下一级分组，分组高度等于分组头高度 + 子分组高度
    if (group.groups.length) {
      return Group.heightOfGroups(group.groups) + headerHeight;
    }
    // 展开状态，但无下级分组，高度为分组内记录高度 + 分组头高度
    const startIndex = group.startIndex || 0;
    const endIndex = group.endIndex || 0;
    return (endIndex - startIndex + 1) * Config.cellHeight + headerHeight;
  }
  static heightOfGroups(groups = []) {
    const height = groups.reduce(
      (sum, item) => sum + Group.heightOfGroup(item),
      0
    );
    return height + groups.length * Config.groupGutter;
  }
  static flatten(group) {
    return flattenGroups([group]);
  }
  static flattenGroups(...params) {
    return flattenGroups(...params);
  }
  /**
   * 从普通 js 对象生成 Group
   * @param {Array|Object} param 一个或多个 js 对象
   * @returns {Group|Group[]}
   */
  static from(param) {
    if (Array.isArray(param)) {
      return param.map(parse);
    }
    return parse(param);
  }
}

/**
 * 扁平化分组数据，并计算每个分组的 offsetTop、offsetLeft
 * @param {Array} groups 原始 groupData，多级嵌套了子分组
 * @param {Number} initTopGutter 第一个分组的 top-gutter
 * @returns {Array} 扁平化的 groups
 */
export function flattenGroups(
  groups = [],
  initTopGutter = Config.groupGutter + Config.groupCellHeight
) {
  if (!groups.length) return groups;
  let offsetTop = initTopGutter || 0;
  function reducer(arr, group) {
    const initChildTop = offsetTop + Config.groupCellHeight;
    const flattenedGroups = Group.flattenGroups(group.groups, initChildTop);
    group.offsetTop = offsetTop;
    group.offsetLeft = group.groupLevel * Config.groupGutter;
    offsetTop += Group.heightOfGroup(group) + Config.groupGutter;
    return arr.concat(group, flattenedGroups);
  }
  return groups.reduce(reducer, []);
}

/**
 * 将 js 对象解析为 Group
 * @param {Object} data Group 构造参数
 * @returns {Group}
 */
export function parse(data) {
  const group = new Group(data);
  group.groups = group.groups.map(item => {
    const subgroup = parse(item);
    subgroup.parent = group;
    return subgroup;
  });
  return group;
}

export default Group;
