<script>
const typeLabelMap = {
  count: "记录数",
  distinct: "唯一记录数",
  sum: "求和",
  avg: "平均值",
  min: "最小值",
  max: "最大值"
};
export default {
  functional: true,
  render(h, context) {
    let { statsType, statsResult } = context.props.statistic || {};
    if (statsResult && statsResult.toLocaleString) {
      statsResult = statsResult.toLocaleString();
    }
    const content = [];
    if (statsType) {
      content.push(typeLabelMap[statsType]);
      content.push(" " + statsResult);
      content.push(<i class="ndl-icon-chevron-down icon"></i>);
    } else {
      content.push(
        <span class="placeholder">
          统计<i class="ndl-icon-chevron-down icon"></i>
        </span>
      );
    }
    return <div {...context.data}>{content}</div>;
  }
};
</script>
