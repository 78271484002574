<template>
  <div class="pane-footer">
    <!-- <span class="cell cell-index">共{{ total | locale }}条记录</span> -->
    <div class="grid-view is-fixed" ref="footerFixed">
      <div class="cell-wrapper" :style="viewStyleMap.fixedView">
        <CellFooter
          v-for="(item, fixedIndex) in fixedColumns"
          :key="'fixed_' + fixedIndex"
          :statistic="statistics[item.columnId]"
          :index="item.columnIndex"
          :data-col="item.columnIndex"
          :style="cellStyleMap[item.columnIndex]"
          class="cell"
          @click="onCellClick(item, $event)"
          @contextmenu.prevent="onCellClick(item, $event)"
        />
      </div>
    </div>
    <div class="grid-view is-scroll" ref="footerScroll">
      <div class="cell-wrapper" :style="viewStyleMap.scrollView">
        <CellFooter
          v-for="(item, staticIndex) in renderColumns"
          :key="'static_' + staticIndex"
          :statistic="statistics[item.columnId]"
          :index="item.columnIndex"
          :data-col="item.columnIndex"
          :style="cellStyleMap[item.columnIndex]"
          class="cell"
          @click="onCellClick(item, $event)"
          @contextmenu.prevent="onCellClick(item, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Position from "./model/scroll-position";
import mixinPane from "./helper/mixin-pane";
import CellFooter from "./renders/CellFooter";
import { calcWidth, calcGroupOffset } from "./helper/util-calc";
import { findCell } from "./cell-editor/utils";
import config from "./config";

export default {
  mixins: [mixinPane],
  components: { CellFooter },
  filters: {
    locale(value) {
      const num = Number(value);
      return isNaN(num) ? value : num.toLocaleString();
    }
  },
  methods: {
    calcStyle() {
      const { cellWidth, indexCellWidth, cellHeight } = config;
      const { fixedColumns, staticColumns, renderColumns } = this;
      const fixedWidth = calcWidth(fixedColumns);
      const staticWidth = calcWidth(staticColumns);
      const groupOffset = calcGroupOffset(this.groupData);

      /**
       * 计算各部分容器尺寸
       */
      const fixedView = {
        width: `${fixedWidth + indexCellWidth + groupOffset}px`,
        height: cellHeight + "px"
      };
      const scrollView = {
        width: staticWidth + "px",
        height: cellHeight + "px"
      };
      /**
       * 计算单元格尺寸和位置
       */
      const cellStyleMap = {};
      // 固定列单元格
      for (const column of fixedColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        cellStyleMap[column.columnIndex] = {
          width: columnWidth + "px",
          height: cellHeight + "px",
          left: column.columnLeft + indexCellWidth + groupOffset + "px",
          top: 0
        };
      }
      // 其余列单元格
      for (const column of renderColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        cellStyleMap[column.columnIndex] = {
          width: columnWidth + "px",
          height: cellHeight + "px",
          left: column.columnLeft + "px",
          top: 0
        };
      }

      this.viewStyleMap = Object.freeze({ fixedView, scrollView });
      this.cellStyleMap = Object.freeze(cellStyleMap);
    },
    getScrollPosition() {
      return Position.from(this.$refs.footerScroll);
    },
    scrollTo({ left = 0 }) {
      const elm = this.$refs.footerScroll;
      if (elm) elm.scrollLeft = left;
    },
    onCellClick(item, evt) {
      const cellElement = findCell(evt.target, ".pane-footer .cell");
      this.$emit("click", item, cellElement, evt);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "./style.less";

.pane-footer {
  color: @footer-color;
  font-size: 12px;
  .cell {
    border: none;
    background: none;
    padding-bottom: 12px;
    cursor: pointer;
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      background-color: @primary-color-light !important;
    }
    .placeholder {
      visibility: hidden;
    }
    &:hover .placeholder {
      visibility: visible;
    }
    .icon {
      margin-left: 3px;
    }
  }
  .cell-index {
    left: 0;
    color: fade(black, 45%);
  }
}
</style>
