import isObject from "lodash/isObject";

export default class Argument {
  constructor(data) {
    Object.assign(
      this,
      {
        // 参数名称
        label: "参数",
        // 参数来自工作表中的列
        columnId: null,
        // 参数来自用户输入的值
        customValue: null,
        // 判断参数是否由用户自己输入
        isCustom: false,
        // 参数是否必填
        required: false
      },
      data
    );
  }
  static from(...params) {
    const firstArg = params[0];
    if (isObject(firstArg)) return new Argument(firstArg);
    return new Argument({
      label: params[0] || "参数",
      required: !!params[1],
      isCustom: !!params[2]
    });
  }
  static merge(target, source) {
    if (!target || !source) return target;
    target.forEach((item, index) => {
      const sourceItem = source[index] || {};
      if (sourceItem.columnId) item.columnId = sourceItem.columnId;
      if (sourceItem.customValue) item.customValue = sourceItem.customValue;
      if ("isCustom" in sourceItem) item.isCustom = !!sourceItem.isCustom;
    });
    return target;
  }
}
