import contextify from "@/components/contextify";
// eslint-disable-next-line no-unused-vars
import Column from "@/model/column";
import { findExpression } from "@/views/datamodel/utils/column-expressions";

/**
 * 编辑表达式
 * @param {Object} options
 * @param {String} options.func 表达式类型
 * @param {Column} options.column 表达式所属列
 * @param {Element|Event} options.source contextify 组件所需的鼠标位置或元素位置
 * @param {Array} options.columns 工作表中的列，部分表达式支持使用其它列作为函数参数
 * @param {Function} onConfirm 表达式编辑完成后的回调函数，onConfirm(expression)。
 * 可以在 onConfirm 发送相关请求以同步用户操作。
 * @returns {any}
 */
export default function({ func, column, source, columns }, onConfirm) {
  // 初始化 expression 对象，避免读取 column.expression 时报错
  if (!column.expression) {
    column.expression = { func: null };
  }
  const expression = findExpression(func);
  /**
   * Case 1: 选择的 func 并没有找到对应的表达式，
   * 说明点击了「取消」选项，或者未知的选项。
   * 一般来说，传入的 func 都能通过 findExpression 找到对应的表达式。
   */
  if (!expression) {
    return onConfirm && onConfirm({ func: null });
  }
  /**
   * Case 2: 所选表达式没有 args 参数（无需填写表达式参数），
   * 不需要填参数时不用弹出 context，可直接 return。
   *
   * 若所选的表达式与该列当前应用的表达式不一致，则调用 onConfirm()
   * 以向服务端发送相关请求（onConfirm 参数）。
   */
  if (!expression.args) {
    if (expression.func !== column.expression.func) {
      onConfirm && onConfirm(expression);
    }
    return;
  }
  /**
   * Case 3: 其余的表达式一般支持填写参数。
   *
   * 存在函数参数时，弹出 context 以编辑相关参数。
   */
  const opts = {
    column,
    columns,
    source,
    onConfirm,
    defaultFunc: func,
    inverse: { y: false, x: true },
    style: { overflow: "visible" }
  };
  return contextify(
    () => import(/* webpackChunkName: "spreadsheet" */ "./src/EditExpression"),
    opts
  ).wait();
}
