import Types from "@/constant/column-types";
import Argument from "@/model/expression-argument";

const expressionMap = {
  // 文本列表达式
  [Types.text]: [
    { func: "LOWER", label: "小写" },
    { func: "UPPER", label: "大写" },
    { func: "LTRIM", label: "删除头部空格" },
    { func: "RTRIM", label: "删除尾部空格" },
    {
      func: "CONCAT",
      label: "拼接字符串",
      args: [
        Argument.from("字符串1", true),
        Argument.from("字符串2", false, true)
      ]
    },
    {
      func: "SUBSTRING",
      label: "子字符串",
      args: [
        Argument.from("起始位置", true, true),
        Argument.from("子字符串长度", false, true)
      ]
    },
    { func: "REVERSE", label: "倒序" },
    {
      func: "REPLACE",
      label: "替换",
      args: [
        Argument.from("查找字符串", true, true),
        Argument.from("替换字符串", true, true)
      ]
    },
    {
      func: "POSITION",
      label: "字符串位置",
      args: [Argument.from("目标字符串", true, true)]
    }
  ],
  // 数值列表达式
  [Types.number]: [
    { func: "ROUND", label: "近似值" },
    { func: "ABS", label: "绝对值" },
    { func: "SQRT", label: "平方根" },
    { func: "LOG", label: "自然对数" },
    { func: "LOG10", label: "以10为底的对数" },
    { func: "CEIL", label: "向上取整" },
    { func: "FLOOR", label: "向下取整" },
    { func: "SIN", label: "正弦" },
    { func: "COS", label: "余弦" },
    { func: "TAN", label: "正切" },
    { func: "COT", label: "余切" }
  ],
  // 日期列表达式
  [Types.date]: [
    { func: "CURRENT_TIME", label: "当前时间" },
    { func: "CURRENT_DATE", label: "当前日期" },
    {
      func: "TIMESTAMPADD",
      label: "时间相加",
      args: [
        Argument.from({
          label: "时间单位",
          customValue: "MINUTE",
          isCustom: true,
          required: true
        }),
        Argument.from("数值", true, true),
        Argument.from("时间值", false)
      ]
    },
    {
      func: "TIMESTAMPDIFF",
      label: "时间相减",
      args: [
        Argument.from({
          label: "时间单位",
          customValue: "MINUTE",
          isCustom: true,
          required: true
        }),
        Argument.from("开始时间", true),
        Argument.from("结束时间", false, true)
      ]
    }
  ]
};

export function findExpression(func) {
  return []
    .concat(
      expressionMap[Types.text],
      expressionMap[Types.number],
      expressionMap[Types.date]
    )
    .find(item => item.func === func);
}

export default expressionMap;
