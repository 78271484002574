/**
 * 仅用于渲染一个标签，少写几行重复代码，也方便统一修改
 */
export default {
  functional: true,
  render(h, context) {
    const props = context.props;
    const TagName = props.tag || "div";
    let classes = "ndl-text-desc-small ndl-margin-bottom-xs";
    if ("marginTop" in props && props.marginTop !== false) {
      classes += " ndl-margin-top";
    }
    return (
      <TagName class={classes} {...context.data}>
        {props.text}
        {context.children}
      </TagName>
    );
  }
};
