<template>
  <div :style="wrapperStyle" :class="wrapperClass">
    <div
      v-for="(column, index) in columns"
      :key="index"
      :style="cellStyles[index]"
      :class="cellClass"
    >
      <!-- 分组头，仅固定列中的第一列渲染 -->
      <div v-if="fixed && index === 0" class="group-header">
        <!-- 折叠图标 -->
        <i
          :class="[
            'header-left',
            group.expanded ? 'ndl-icon-chevron-down' : 'ndl-icon-chevron-right'
          ]"
          @click="onClick"
          @contextmenu.prevent="onContextMenu"
        ></i>
        <!-- 分组名称、值 -->
        <div class="header-grow">
          <span class="header-label ndl-ellipsis">
            {{ group.columnName }}
          </span>
          <span class="ndl-flex">
            <component
              :is="valueRender"
              :value="group.value"
              :column="groupColumn"
            />
          </span>
        </div>
        <!-- 分组头中的统计信息 -->
        <CellStatistic
          class="header-right cell-content"
          :statistic="statistics[column.columnId]"
          @click="onCellStatisticClick(column, $event)"
          @contextmenu.prevent="onCellStatisticClick(column, $event)"
        />
      </div>
      <!-- 其余列渲染分组统计信息 -->
      <CellStatistic
        v-else
        class="cell-content"
        :statistic="statistics[column.columnId]"
        @click="onCellStatisticClick(column, $event)"
        @contextmenu.prevent="onCellStatisticClick(column, $event)"
      />
    </div>
  </div>
</template>

<script>
import config from "../config";
import Types from "../define/column-types";
import CellStatistic from "./CellFooter.vue";
import CellText from "./CellContentText.vue";
import CellRadio from "./CellContentRadio.vue";
import { findCell } from "../cell-editor/utils";

export default {
  components: { CellStatistic },
  inject: ["sheetRoot"],
  props: {
    group: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    parentStyle: Object,
    // 分组是否渲染在 fixed-view 中
    fixed: Boolean
  },
  computed: {
    wrapperStyle() {
      const { offsetTop, offsetLeft, height, groupLevel } = this.group;
      const parentStyle = this.parentStyle || { width: "0px" };
      // fixed-view 的宽度通过 right: 0 实现自适应
      // scroll-view 的宽度通过 parentStyle 计算，注意多级分组时的 gutter
      const width = this.fixed
        ? null
        : `calc(${parentStyle.width} - ${groupLevel * config.groupGutter}px)`;
      return {
        top: offsetTop - config.groupCellHeight + "px",
        left: (this.fixed ? offsetLeft : 0) + "px",
        width,
        height: height + "px"
      };
    },
    /**
     * 计算单元格尺寸、位置
     * ⚠️ 注意 fixed-view 和 scroll-view 的区别
     * fixed-view 中包含了分组头，第一个单元格需要加上索引列
     * 和因分组缩进导致的分组间宽度差异
     */
    cellStyles() {
      const { cellWidth, indexCellWidth, groupGutter } = config;
      const { maxGroupLevel, groupLevel } = this.group;
      // 减 1 是为了留出 border-bottom 位置
      const height = config.groupCellHeight - 1 + "px";
      // scroll-view 中的列位置直接根据 columnLeft 设置就行了，不需要偏移
      let firstCellOffset = 0;
      // fixed-view 中第一列的宽度需要加上索引列宽度，以及下一级分组缩进留下的空白
      if (this.fixed) {
        firstCellOffset = (maxGroupLevel - groupLevel) * groupGutter;
        firstCellOffset += indexCellWidth;
      }
      return this.columns.map((item, index) => {
        let width = item.columnWidth || cellWidth || 0;
        let left = item.columnLeft || 0;
        // 仅第一列宽度需要加上 firstCellOffset
        if (index === 0) width += firstCellOffset;
        // 第二列开始，left 需要加上第一列宽度偏移值
        else left += firstCellOffset;
        return { height, left: left + "px", width: width + "px" };
      });
    },
    wrapperClass() {
      return ["group-wrapper", this.fixed ? "is-fixed" : "is-static"];
    },
    cellClass() {
      // 当分组没有下级分组时，显示分组头的底边框
      const showBorder = !this.group.groups.length;
      return ["group-cell", { "show-border": showBorder }];
    },
    statistics() {
      return this.group.statistics || {};
    },
    groupColumn() {
      const columnIdMap = this.sheetRoot.columnIdMap || {};
      return columnIdMap[this.group.columnId] || {};
    },
    valueRender() {
      if (this.groupColumn.columnType === Types.radio) return CellRadio;
      return CellText;
    }
  },
  methods: {
    afterExpand() {
      this.$parent.$emit("group-expand", this.group.expanded, this.group);
    },
    onClick() {
      this.group.toggle();
      this.afterExpand();
    },
    onContextMenu(evt) {
      this.$parent.$emit(
        "group-contextmenu",
        this.group,
        evt,
        this.afterExpand
      );
    },
    onCellStatisticClick(column, evt) {
      const cellElement = findCell(evt.target, ".cell-content");
      this.$parent.$emit("group-statistic", column, cellElement, evt);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../style.less";

.group-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.45);
  border: @border;
  overflow: hidden;
  &.is-static {
    border-left: none;
    border-top-right-radius: @border-radius;
    border-bottom-right-radius: @border-radius;
  }
  &.is-fixed {
    border-right: none;
    border-top-left-radius: @border-radius;
    border-bottom-left-radius: @border-radius;
  }
  .group-cell {
    position: absolute;
    border-bottom: solid 1px transparent;
    &.show-border {
      border-color: @border-color;
    }
  }
  .group-header,
  .cell-content {
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
  }
  .group-header {
    .header-left {
      padding: 3px;
      margin: 0 8px;
      cursor: pointer;
      border-radius: 100%;
      &:hover {
        background: #f0f0f0;
      }
    }
    .header-grow {
      width: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .header-label {
      font-size: 12px;
      color: fade(black, 45%);
    }
    .header-right {
      flex-shrink: 0;
      min-width: 50px;
      max-width: 60%;
    }
  }
  .cell-content {
    justify-content: flex-end;
    white-space: nowrap;
    overflow: hidden;
    color: @footer-color;
    font-size: 12px;
    padding: @cell-padding;
    &:hover {
      background-color: @primary-color-light !important;
    }
    * {
      pointer-events: none;
    }
    .placeholder {
      visibility: hidden;
    }
    &:hover .placeholder {
      visibility: visible;
    }
    .icon {
      margin-left: 3px;
    }
  }
}
</style>
