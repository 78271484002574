/**
 * 一个便于获取滚动位置的工具类
 */
export default class ScrollPosition {
  constructor(elm) {
    const {
      scrollLeft = 0,
      scrollTop = 0,
      scrollWidth = 0,
      scrollHeight = 0,
      clientWidth = 0,
      clientHeight = 0,
      offsetHeight = 0,
      offsetWidth = 0
    } = elm || {};
    Object.assign(this, {
      scrollLeft,
      scrollTop,
      scrollWidth,
      scrollHeight,
      clientWidth,
      clientHeight,
      offsetHeight,
      offsetWidth
    });
  }
  get left() {
    return this.scrollLeft;
  }
  set left(value) {
    this.scrollLeft = Math.max(0, Math.min(value, this.maxScrollLeft));
  }
  get top() {
    return this.scrollTop;
  }
  set top(value) {
    this.scrollTop = Math.max(0, Math.min(value, this.maxScrollTop));
  }
  get maxScrollLeft() {
    return this.scrollWidth - this.offsetWidth;
  }
  get maxScrollTop() {
    return this.scrollHeight - this.offsetHeight;
  }
  static from(elm) {
    return new ScrollPosition(elm);
  }
}
