export default {
  methods: {
    onResizeMouseDown(evt) {
      const $el = this.$el;
      const bounding = $el.getBoundingClientRect();
      const column = this.column;
      const basePayload = {
        $el,
        column,
        bounding,
        startEvent: evt
      };
      const startX = evt.clientX;
      let diff = 0;
      this.$emit("resize-start", {
        ...basePayload,
        diff,
        endEvent: evt
      });
      const onMouseMove = moveEvt => {
        diff = moveEvt.clientX - startX;
        this.$emit("resize", {
          ...basePayload,
          diff,
          endEvent: moveEvt
        });
        this.resizing = true;
      };
      const onMouseUp = upEvt => {
        this.$emit("resized", {
          ...basePayload,
          diff,
          endEvent: upEvt
        });
        // 延迟 10ms 更新 resizing 状态，避免触发 cell-header 的 click 事件
        setTimeout(() => {
          this.resizing = false;
        }, 10);
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
  }
};
