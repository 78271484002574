import { Group, flattenGroups } from "../model/group";

/**
 * 折半查找 index 所属分组，请保证 groups 有序
 * @param {Array} groups 最后一级分组，其中 groups[].groups 为空数组
 * @param {*} index 工作表记录行号
 * @returns {Group} 返回行号所在分组，找不到则返回 null
 */
function findGroup(groups = [], index = 0) {
  let len = groups.length;
  if (!len) return null;
  const cursor = Math.floor(len / 2);
  const midGroup = groups[cursor];
  if (index < midGroup.startIndex) {
    return findGroup(groups.slice(0, cursor), index);
  }
  if (index > midGroup.endIndex) {
    return findGroup(groups.slice(cursor + 1), index);
  }
  return groups[cursor];
}

export default {
  data() {
    return {
      flattenedGroups: flattenGroups(this.groupData)
    };
  },
  created() {
    this.checkGroupData();
  },
  watch: {
    groupData() {
      this.checkGroupData();
      this.updateFlattenedGroups();
    }
  },
  methods: {
    checkGroupData() {
      function checkGroups(groups) {
        if (!Array.isArray(groups)) {
          const error = new TypeError("无效分组信息，必须为 Array<Group>");
          console.error(error, groups);
        }
        for (const group of groups) {
          if (!(group instanceof Group)) {
            const error = new TypeError("无效分组，必须为 Group");
            console.error(error, group, groups);
            break;
          }
          checkGroups(group.groups);
        }
      }
      checkGroups(this.groupData);
    },
    updateFlattenedGroups() {
      this.flattenedGroups = flattenGroups(this.groupData);
    },
    /**
     * 通过代理实现分组查找，生成分组 startIndex map
     * @returns {Proxy}
     */
    getGroupIndexMap() {
      const groups = this.flattenedGroups.filter(item => !item.groups.length);
      const proxyTarget = groups.reduce((map, item) => {
        map[item.startIndex] = item;
        return map;
      }, {});
      const defaultGroup = new Group();
      const proxyHandler = { get: () => defaultGroup };
      if (groups.length) {
        proxyHandler.get = function(target, prop) {
          if (target[prop]) return target[prop];
          return findGroup(groups, prop) || defaultGroup;
        };
      }
      return new Proxy(proxyTarget, proxyHandler);
    }
  }
};
