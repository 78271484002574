<template>
  <div class="pane-wrapper ndl-padding">
    <!-- <div class="ndl-margin-bottom">
        <span class="ndl-text-desc-small">条件转换</span>
        <span class="ndl-text-small ndl-color-red">
          <i class="ndl-icon-alert-triangle ndl-margin-left"></i>
          仅允许一个「其它」条件
        </span>
      </div> -->
    <div class="ndl-margin-bottom ndl-text-desc-small">条件转换</div>

    <div class="condition-wrapper" ref="wrapper">
      <div v-for="(item, index) in transform" :key="index">
        <div
          v-for="(condition, index2) in item.conditions"
          :key="index2"
          class="condition-row"
        >
          <a-select
            class="operator"
            :dropdown-match-select-width="false"
            :get-popup-container="() => $el"
            @change="value => handleChange(value, index)"
            :default-value="item.columnId"
          >
            <a-select-option
              v-for="item3 in column"
              :key="item3.columnId"
              :value="item3.columnId"
            >
              {{ item3.columnName }}
            </a-select-option>
          </a-select>
          ->
          <span :data-index="index" class="index">{{ index + 1 }}</span>
          <a-select
            :id="componentId(index, item)"
            v-model="condition.operatorType"
            class="operator"
            :get-popup-container="() => $el"
          >
            <a-select-option
              v-for="item4 in operators(item)"
              :key="item4.value"
              :value="item4.value"
            >
              {{ item4.label }}
            </a-select-option>
            <a-select-option
              :value="elseOption.value"
              :disabled="checkElseOption(condition)"
            >
              {{ elseOption.label }}
            </a-select-option>
          </a-select>
          <component
            @click="clickComponent(item)"
            :is="conditionComponent(item)"
            :condition="condition"
            :get-popup-container="() => $el"
            :filter-values="filterValuesPart"
            @on-search="getFilterValues({}, item)"
          />
          <i class="remove ndl-icon-x" @click="remove(index, index2)"></i>
        </div>
      </div>
    </div>
    <div class="ndl-flex ndl-flex--center ndl-margin-top-sm">
      <a @click="add()"><i class="ndl-icon-plus"></i>添加条件</a>
    </div>
  </div>
</template>

<script>
import FilterTypes, { ColumnFilterTypes } from "@/constant/filter-types";
import ColumnTypes from "@/constant/column-types";
import Filter, { pickAttr } from "@/model/filter";
import Actions from "@/constant/datasheet-actions";
import workspace from "@/views/datamodel/models/workspace";
import CaseWhen from "./model-casewhen";
import getComponent from "./get-condition-component";

export default {
  props: {
    column: {
      type: Array,
      required: true
    },
    transform: {
      type: Array,
      required: true
    }
  },
  created() {
    this.transformItem = {};
  },
  data() {
    return {
      selectColumn: "",
      selectIndex: 0,
      columnItem: {},
      transformItem: {},
      elseOption: { value: FilterTypes.ELSE, label: "其它" },
      filterValues: null,
      filterValuesPart: []
    };
  },
  computed: {
    conditions() {
      return this.transform[this.selectIndex].conditions || [];
    }
  },
  methods: {
    async getFilterValues(options = {}, item) {
      this.filterValuesPart = [];
      this.columnItem = item;
      //   if (!this.filterValues) {
      const columnMeta = pickAttr(item || {});
      const filter = new Filter(columnMeta);
      const selection = { type: "and", conditions: [filter] };
      const tempOptions = { ...options, keyword: null };
      const action = Actions.filterValues(
        {
          ...tempOptions,
          filter,
          selection,
          page: 1,
          // 这里提供 2000 条用户选择应该够了吧，太多选项也是够呛
          size: 2000
        },
        chunk => (chunk.disableLoading = true)
      );
      let { data = [] } = await workspace.currentDatasheet.send(action);
      data = data.map(value => ({ value }));
      this.filterValues = data;
      //   }

      const list = this.filterValues;
      //   if (options.keyword || options.keyword === 0) {
      //     this.filterValuesPart = list.filter((item) =>
      //       item.value.includes(options.keyword)
      //     );
      //   } else {
      this.filterValuesPart = Array.from(list);
      //   }
      return this.filterValuesPart;
    },
    operators(item) {
      const type = item.columnType;
      const text = ColumnTypes.text;
      return ColumnFilterTypes[type] || ColumnFilterTypes[text];
    },
    conditionComponent(condition) {
      const type = condition.columnType;
      return getComponent(type);
    },
    async add() {
      this.transform.push({ ...this.column[0], conditions: [new CaseWhen()] });
      await this.$nextTick();
      const wrapper = this.$refs.wrapper;
      // 自动滚动到底部
      wrapper.scrollTo({
        top: 1000000,
        left: 0,
        behavior: "smooth"
      });
    },
    componentId(a, b) {
      return `component${a}${b.columnId}`;
    },
    remove(index, index2) {
      this.transform[index].conditions.splice(index2, 1);
    },
    /**
     * 存在 else 条件时，其它条件的 else 选项将禁用
     */
    checkElseOption(condition) {
      const finder = item => item.operatorType === FilterTypes.ELSE;
      const existCondition = this.conditions.find(finder);
      return existCondition && existCondition !== condition;
    },
    handleChange(value, index) {
      this.column.forEach(item => {
        if (item.columnId == value) {
          this.transform.splice(index, 1, {
            ...item,
            conditions: [new CaseWhen()]
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@row-gutter: 8px;
@index-gutter: 30px;
.pane-wrapper {
  width: 500px + @index-gutter;
  .condition-wrapper {
    padding: 6px 0;
    margin: 6px 0;
    max-height: 380px;
    overflow: auto;
  }
  .condition-row {
    display: flex;
    align-items: center;
    margin-bottom: @row-gutter;
    position: relative;
    padding-left: @index-gutter;
  }
  .operator {
    width: 100px;
    flex-shrink: 0;
    margin-right: @row-gutter / 2;
  }
  .value-from,
  .value-to {
    width: 0;
    flex: 1;
    margin: 0 @row-gutter / 2;
  }
  .indicator,
  .remove {
    width: 20px;
    color: #bfbfbf;
    flex-shrink: 0;
  }
  .indicator {
    text-align: center;
  }
  .remove {
    cursor: pointer;
    transition: color 0.2s;
    text-align: right;
    &:hover {
      color: #ff4d4f;
    }
  }
  .index {
    @size: 20px;
    width: @size;
    height: @size;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    background: rgb(187, 207, 255);
  }
  .index[data-index="0"] {
    background: rgb(85, 134, 255);
  }
  .index[data-index="1"] {
    background: rgb(119, 158, 255);
  }
  .index[data-index="2"] {
    background: rgb(153, 182, 255);
  }
}
</style>
