<script>
export default {
  functional: true,
  render(h, context) {
    const classes = [
      "ndl-ellipsis",
      context.data.staticClass,
      context.data.class
    ];
    return <span class={classes}>{context.props.visualValue}</span>;
  }
};
</script>
