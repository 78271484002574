import CellGroup from "../renders/CellGroup";
import throttle from "lodash/throttle";

/**
 * 各区域都会用到的东西
 */
export default {
  components: { CellGroup },
  inject: ["sheetRoot"],
  props: {
    // 工作表完整列
    columns: {
      type: Array,
      required: true
    },
    // 工作表完整记录
    data: {
      type: Array,
      required: true
    },
    // 统计数据
    statistics: {
      type: Object,
      default: () => ({})
    },
    // 分组数据
    groupData: {
      type: Array,
      default: () => []
    },
    // 工作表记录总数
    total: {
      type: [Number, String],
      default: 0
    },
    // columns 划分出的固定列部分、非固定列部分
    fixedColumns: {
      type: Array,
      required: true
    },
    staticColumns: {
      type: Array,
      required: true
    },
    // 渲染区列
    renderColumns: {
      type: Array,
      required: true
    },
    // 渲染区记录
    renderData: {
      type: Array,
      required: true
    },
    // 渲染区分组
    renderGroup: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // 容器样式
      viewStyleMap: {},
      // 单元格样式
      cellStyleMap: {}
    };
  },
  computed: {
    lastIndex() {
      return this.columns.length - 1;
    }
  },
  created() {
    this.calcStyle();
    this.throttledCalcStyle = throttle(this.calcStyle, 120);
  },
  watch: {
    renderColumns() {
      this.throttledCalcStyle();
    },
    renderData() {
      this.throttledCalcStyle();
    },
    renderGroup() {
      this.throttledCalcStyle();
    }
  },
  methods: {
    calcStyle() {},
    getScrollPosition() {},
    scrollTo() {},
    getGroupIndexMap() {
      const sheetRoot = this.sheetRoot;
      return sheetRoot.getGroupIndexMap();
    }
  }
};
