import Types from "@/constant/column-types";
import ConditionText from "./condition-text";
import ConditionNumber from "./condition-number";
import ConditionDate from "./condition-date";

const map = {
  [Types.text]: ConditionText,
  [Types.number]: ConditionNumber,
  [Types.date]: ConditionDate
};

export default type => map[type] || ConditionText;
