import { Types } from "@/constant/column-types";
import Observer from "@/utils/observer";

export class Formula {
  name = "";
  /**
   * 公式别名，如引用字段时可通过 {A} 等字母序号引用
   */
  alias = "";
  /**
   * 公式返回值类型
   */
  returns = Types.text;
  /**
   * 选择公式后要在编辑器中插入的内容。
   * 如果是一个字符串，则插入后光标位于字符串末尾；
   * 如果是一个字符串数组，则插入后光标位于第一项末尾。
   * @type {string | string[]}
   * @url https://code.visualstudio.com/docs/editor/userdefinedsnippets#_create-your-own-snippets
   */
  snippet = "";
  readme = "";
  usage = "";
  example = "";
  /**
   * 默认都是函数
   */
  isFunction = true;
  /**
   * @param {Formula} opts
   */
  constructor(opts) {
    Object.assign(this, opts);
  }
  get id() {
    return this.isFunction ? this.name : `ref-${this.alias || this.name}`;
  }
  static from(opts) {
    return new Formula(opts);
  }
}

export class Group {
  label = "";
  /**
   * 静态 children，非实时变化
   * @type {Formula[]}
   */
  _static_children = [];
  /**
   * 动态 children，其子项可能会根据外部条件变化
   * @type {function}
   */
  _dynamic_children_provider = null;
  /**
   * @param {Group} opts
   */
  constructor(opts) {
    Object.assign(this, opts);
  }
  /**
   * @type {Formula[]}
   */
  get children() {
    if (this._dynamic_children_provider) {
      return this._dynamic_children_provider.call(this, this);
    }
    return this._static_children;
  }
  /**
   * @param {string} label
   */
  setLabel(label) {
    this.label = label;
  }
  setDynamicChildrenProvider(provider) {
    this._dynamic_children_provider = provider;
  }
  /**
   * @param {Formula} child
   */
  add(child) {
    if (child instanceof Formula) {
      this._static_children.push(child);
    } else {
      this._static_children.push(Formula.from(child));
    }
  }
  static from(opts) {
    return new Group(opts);
  }
}

export class Doc extends Observer {
  /**
   * @type {Group[]}
   */
  groups = [];
  /**
   * @param {Doc} opts
   */
  constructor(opts) {
    super();
    Object.assign(this, opts);
  }
  add(group) {
    if (group instanceof Group) {
      this.groups.push(group);
    } else {
      this.groups.push(Group.from(group));
    }
  }
  /**
   * @returns {Formula[]}
   */
  getAllFormulas() {
    return this.groups.reduce((list, group) => list.concat(group.children), []);
  }
  /**
   * @returns {Formula[]}
   */
  getSupportedFunctions() {
    return this.getAllFormulas().filter(item => item.isFunction);
  }
  static from(opts) {
    return new Doc(opts);
  }
}
