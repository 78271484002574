<template>
  <Draggable
    :list="list"
    :animation="200"
    tag="ul"
    handle=".handle"
    class="list"
  >
    <li v-for="(item, index) in list" :key="index" class="list-item">
      <span
        class="color-rel"
        :style="`--color: ${colorOf(item)}`"
        @click="showPalette(item, $event)"
      ></span>
      <span class="label ndl-ellipsis">{{ item.value }}</span>
      <i class="handle ndl-icon-move-drag"></i>
    </li>
  </Draggable>
</template>

<script>
import Draggable from "vuedraggable";
import showContext from "@/components/contextify";
import { CellColors, uniqueColorBy } from "@/components/spread-sheet";

import workspace from "@/views/datamodel/models/workspace";
import Actions from "@/constant/datasheet-actions";

import Palette from "./OptionRadioPalette";

export default {
  inheritAttrs: false,
  components: { Draggable },
  props: {
    options: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    list() {
      return this.options.palette || [];
    }
  },
  created() {
    this.initPalette();
  },
  methods: {
    colorOf(item) {
      return CellColors[item.color][0] || item.color;
    },
    async initPalette() {
      const action = Actions.columnValues(
        { columnId: this.column.columnId },
        chunk => (chunk.disableLoading = true)
      );
      const { data = [] } = await workspace.currentDatasheet.send(action);
      const palette = this.list;
      const addon = data
        .slice(0, 100)
        .filter(value => palette.every(item => item.value !== value))
        .map(value => {
          const index = uniqueColorBy(value);
          return { value, color: index };
        });
      this.options.palette = palette.concat(addon);
    },
    showPalette(item, evt) {
      const opts = {
        source: evt.target,
        dir: { x: true },
        data: item
      };
      showContext(Palette, opts);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.list {
  margin-top: @ndl-margin;
  max-height: 150px;
  overflow: auto;
}

.list-item {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 6px 4px;
  cursor: pointer;
}
.handle {
  flex-shrink: 0;
  color: @text-color-secondary;
}
.label {
  flex: 1;
  margin: 0 8px;
}
@rel-size: 14px;
.color-rel {
  --color: cyan;
  flex-shrink: 0;
  width: @rel-size;
  height: @rel-size;
  border-radius: 50%;
  position: relative;
  border: solid 2px var(--color);
  padding: 1px;
  background-color: var(--color);
  background-clip: content-box;
  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.08);
  }
}
</style>
