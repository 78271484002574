<template>
  <OptionNumber :options="options">
    <FormLabel text="公式" margin-top />
    <FormulaEditor v-model="options.formula" />
    <FormLabel margin-top class="ndl-flex ndl-flex--middle">
      <span class="ndl-flex-item--grow">格式化为百分比</span>
      <a-switch
        v-model="options.formatAsPercentage"
        size="small"
        style="transform: scale(0.85); margin-left: 2px"
      />
    </FormLabel>
    <FormLabel margin-top class="ndl-flex ndl-flex--middle">
      <span class="ndl-flex-item--grow">
        按文本类型排序
        <a-tooltip title="排序时，默认按照数值类型进行排序">
          <i
            class="ndl-icon-help-circle"
            style="position: relative; top: 1px"
          ></i>
        </a-tooltip>
      </span>
      <a-switch
        v-model="options.sortByText"
        size="small"
        style="transform: scale(0.85); margin-left: 2px"
      />
    </FormLabel>
  </OptionNumber>
</template>

<script>
import OptionNumber from "./OptionNumber";
import FormLabel from "../../form-label";
import FormulaEditor from "@/views/datamodel/components/formula-editor";

export default {
  inheritAttrs: false,
  components: { OptionNumber, FormLabel, FormulaEditor },
  props: {
    options: {
      type: Object,
      required: true
    }
  }
};
</script>
