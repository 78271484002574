<template>
  <div class="ndl-margin-top">
    <FormLabel>精确度</FormLabel>
    <a-radio-group
      v-model="options.precision"
      button-style="solid"
      class="ndl-radio-group fit-content"
    >
      <a-radio-button
        v-for="item in list"
        :key="item.label"
        :value="item.value"
        @mouseup.native="onClick(item)"
      >
        {{ item.label }}
      </a-radio-button>
    </a-radio-group>
    <slot></slot>
  </div>
</template>

<script>
import FormLabel from "../../form-label";

export default {
  inheritAttrs: false,
  components: { FormLabel },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      list: [
        { label: "1", value: 0 },
        { label: "1.0", value: 1 },
        { label: "1.00", value: 2 },
        { label: "1.000", value: 3 },
        { label: "1.0000", value: 4 }
      ]
    };
  },
  // 初始化时处理一下 precision，保证 precision 为 null 或 Number
  created() {
    const options = this.options;
    if (options.precision !== null) {
      const precision = Number(options.precision);
      options.precision = isNaN(precision) ? null : precision;
    }
  },
  methods: {
    async onClick(item) {
      const options = this.options;
      if (options.precision !== item.value) return;
      // 延迟 10ms 再取消选中，因为事件顺序问题
      setTimeout(() => (options.precision = null), 10);
    }
  }
};
</script>
