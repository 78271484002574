import config from "../config";
import ScrollPosition from "../model/scroll-position";
import { throttleByAnimationFrame as throttle } from "@/utils/lib";

const {
  cellWidth,
  cellHeight,
  viewportBufferColumn,
  viewportBufferRow
} = config;

export const defaultScrollPosition = new ScrollPosition({
  offsetWidth: innerWidth,
  offsetHeight: innerHeight
});

/**
 * 计算两个视图垂直方向相交区域的高度
 * 用于计算分组是否在可视区内，而分组特性是水平方向
 * 一定是和可视区 left, width 相等故不用考虑水平方向
 * @param {Array} param0 矩形1 [top, height]
 * @param {Array} param1 矩形2 [top, height]
 * @param {Number} offset 相交高度偏移值
 * @returns {Number} 返回负值说明矩形不相交
 */
function intersect([t1, h1], [t2, h2], offset = 0) {
  const top = Math.max(t1, t2);
  const bottom = Math.min(t1 + h1, t2 + h2);
  return bottom - top + offset;
}

/**
 * 用于计算当前滚动的可视区域需要渲染哪些行和列，可视区域外的数据不渲染以提高性能
 */
export default {
  data() {
    return {
      renderColumns: [],
      renderData: [],
      renderGroup: []
    };
  },
  created() {
    this._updateViewportContent(defaultScrollPosition);
    this.updateViewportContent = throttle(this._updateViewportContent);
  },
  methods: {
    /**
     * 根据滚动位置、分组展开状态计算当前视窗(viewport)
     * 可见的行、列、分组。视窗外的数据不渲染
     * ⚠️ 检查渲染数据是否变化，没变化时不更新数据避免触发渲染
     * @param {ScrollPosition} pos 视窗滚动位置
     */
    _updateViewportContent(pos = defaultScrollPosition) {
      const { renderColumns, renderData, renderGroup } = this;
      const nextColumns = this.updateRenderColumns(pos);
      const nextGroup = this.updateRenderGroup(pos);
      const nextData = this.updateRenderData(pos, nextGroup);
      const len = Math.max(
        renderColumns.length,
        renderData.length,
        renderGroup.length
      );
      let modified = !len;
      for (let i = 0; i < len; i += 1) {
        modified =
          renderColumns[i] !== nextColumns[i] ||
          renderData[i] !== nextData[i] ||
          renderGroup[i] !== nextGroup[i];
        if (modified) break;
      }
      if (modified) {
        this.renderColumns = nextColumns;
        this.renderData = nextData;
        this.renderGroup = nextGroup;
      }
    },
    updateRenderColumns({ scrollLeft, offsetWidth }) {
      const staticColumns = this.bindOptions.staticColumns || [];
      let sum = 0;
      let startIndex = -1;
      let endIndex = 0;
      for (let i = 0, len = staticColumns.length; i < len; i += 1) {
        // 如当前下标之前的列宽总和已超过滚动后的可视区，则可停止遍历
        if (sum - scrollLeft > offsetWidth) {
          break;
        }
        // 累加列宽
        sum += staticColumns[i].columnWidth || cellWidth;
        // 当累加的列宽 >= scrollLeft，说明从该列开始进入可视区
        if (sum - scrollLeft >= 0 && startIndex < 0) {
          startIndex = i;
        }
        // 因为 slice 不包含 endIndex，所以这里需要 +1;
        endIndex = i + 1;
      }
      // 可视区前后保留缓冲列，避免滚动时出现空白
      startIndex = Math.max(0, startIndex - viewportBufferColumn);
      endIndex = endIndex + viewportBufferColumn;
      return staticColumns.slice(startIndex, endIndex);
    },
    updateRenderGroup(pos) {
      const offset = config.viewportBufferGroupOffset;
      const rect1 = [pos.scrollTop, pos.offsetHeight];
      function filterFn(item) {
        const rect2 = [item.offsetTop, item.height];
        return item.visible && intersect(rect1, rect2, offset) > 0;
      }
      return this.flattenedGroups.filter(filterFn);
    },
    updateRenderData({ scrollTop, offsetHeight }, renderGroup = []) {
      const data = this.data || [];
      // 无分组时，直接根据行高计算渲染范围
      if (!renderGroup.length) {
        let startIndex = -1;
        let endIndex = 0;
        startIndex = Math.floor(scrollTop / cellHeight);
        startIndex = Math.max(0, startIndex - viewportBufferRow);
        endIndex = startIndex + Math.ceil(offsetHeight / cellHeight) + 1;
        endIndex = endIndex + viewportBufferRow * 2;
        return data.slice(startIndex, endIndex);
      }
      // 存在分组时，根据分组的 visible、expand、startIndex、endIndex 筛选需要渲染的记录
      const rect1 = [scrollTop, offsetHeight];
      function reducer(arr, { startIndex, endIndex, offsetTop, height }) {
        // 分组还处于可视区下方时，不渲染该分组的记录
        if (scrollTop + offsetHeight < offsetTop) return arr;
        // 分组已经进入可视区，则有 scrollTop > offsetTop
        // 此时根据 scrollTop 和 offsetTop 的差值计算分组内
        // 第一条出现在可视区的记录 startIndex
        // ⚠️ 用 Math.floor 而不是 Math.ceil 避免出现一条记录
        // 滚动了一半时，因向上取整导致没渲染而在顶部留下空白问题
        if (scrollTop > offsetTop) {
          const offsetIndex = (scrollTop - offsetTop) / cellHeight;
          startIndex += Math.floor(offsetIndex);
        }
        if (startIndex > endIndex) startIndex = endIndex;
        // 计算出了 startIndex 后仍需知道分组内有多少条记录需要渲染
        // 此时根据矩阵相交高度计算出最大显示多少行，注意这里用 Math.ceil
        let maxRows = intersect(rect1, [offsetTop, height]) / cellHeight;
        maxRows = Math.ceil(maxRows) + viewportBufferRow;
        endIndex = Math.min(startIndex + maxRows, endIndex);
        const parts = data.slice(startIndex, endIndex + 1);
        return arr.concat(parts);
      }
      // ⚠️ 注意，只需要筛选最后一级分组（groups 为空数组）的数据
      return renderGroup
        .filter(item => item.expanded && !item.groups.length)
        .reduce(reducer, []);
    }
  }
};
