<template>
  <div class="arg-wrapper">
    <ArgItem :item="unitArg" :show-switcher="false" class="ndl-margin-top">
      <a-select
        v-model="unitArg.customValue"
        class="ndl-display-block"
        :get-popup-container="getPopupContainer"
      >
        <a-select-option v-for="item in timeUnitList" :key="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </ArgItem>
    <ArgItem :item="valueArg" :show-switcher="false" class="ndl-margin-top">
      <a-input v-model="valueArg.customValue" placeholder="请输入数值" />
    </ArgItem>
    <ArgItem :item="dateArg" class="ndl-margin-top">
      <a-input
        v-if="dateArg.isCustom"
        v-model="dateArg.customValue"
        :key="func"
        placeholder="请输入时间，默认为当前时间"
      />
      <SelectColumn
        v-else
        v-model="dateArg.columnId"
        block
        :columns="columns"
        :get-popup-container="getPopupContainer"
        placeholder="请选择列"
        style="width: 100%"
      />
    </ArgItem>
  </div>
</template>

<script>
import mixin from "./mixin-arg-editor";
import Argument from "@/model/expression-argument";

export default {
  name: "ArgEditorDateAdd",
  mixins: [mixin],
  computed: {
    unitArg() {
      return this.args[0] || new Argument();
    },
    valueArg() {
      return this.args[1] || new Argument();
    },
    dateArg() {
      return this.args[2] || new Argument();
    }
  }
};
</script>
