<template>
  <div class="pane-header">
    <div class="grid-view is-fixed" ref="headerFixed">
      <div class="cell-wrapper" :style="viewStyleMap.fixedView">
        <div class="cell cell-index" :style="cellStyleMap[-1]" data-col="-1">
          <span>#</span>
        </div>
        <CellHeader
          v-for="(item, fixedIndex) in fixedColumns"
          :key="'fixed_' + fixedIndex"
          :column="item"
          :index="item.columnIndex"
          :style="cellStyleMap[item.columnIndex]"
          :data-col="item.columnIndex"
          :data-first-col="item.columnIndex == 0"
          :data-last-col="item.columnIndex == lastIndex"
          @resize-start="onCellResizeStart"
          @resize="onCellResize"
          @resized="onCellResized"
          @contextmenu="onCellContextmenu"
        />
      </div>
    </div>
    <div class="grid-view is-scroll" ref="headerScroll">
      <div class="cell-wrapper" :style="viewStyleMap.scrollView">
        <CellHeader
          v-for="(item, staticIndex) in renderColumns"
          :key="'static_' + staticIndex"
          :column="item"
          :index="item.columnIndex"
          :style="cellStyleMap[item.columnIndex]"
          :data-col="item.columnIndex"
          :data-first-col="item.columnIndex == 0"
          :data-last-col="item.columnIndex == lastIndex"
          @resize-start="onCellResizeStart"
          @resize="onCellResize"
          @resized="onCellResized"
          @contextmenu="onCellContextmenu"
        />
      </div>
    </div>
    <!-- 固定列阴影线 -->
    <div
      v-show="hasScroll"
      class="shadow-line"
      :style="viewStyleMap.shadowLine"
    ></div>
    <!-- resize column width 指示线 -->
    <div
      v-if="showResizeIndicator"
      ref="resizeIndicator"
      class="resize-indicator"
    ></div>
  </div>
</template>

<script>
import CellHeader from "./renders/CellHeader";
import Position from "./model/scroll-position";
import mixinPane from "./helper/mixin-pane";
import { calcWidth, calcGroupOffset } from "./helper/util-calc";
import config from "./config";

export default {
  mixins: [mixinPane],
  components: { CellHeader },
  data() {
    return {
      hasScroll: false,
      showResizeIndicator: true
    };
  },
  methods: {
    onCellResizeStart() {
      this.showResizeIndicator = true;
      document.body.style.cursor = "col-resize";
    },
    onCellResize({ bounding, diff, show = true }) {
      cancelAnimationFrame(this.cellResizeAnimateId);
      this.cellResizeAnimateId = requestAnimationFrame(() => {
        const elm = this.$refs.resizeIndicator;
        this.showResizeIndicator = show;
        if (!elm) return;
        const paneBounding = this.$el.getBoundingClientRect();
        const left = bounding.right - paneBounding.left + diff;
        elm.style.left = left + "px";
      });
    },
    onCellResized({ bounding, diff, column }) {
      this.showResizeIndicator = false;
      document.body.style.cursor = null;
      column.columnWidth = Math.max(config.minCellWidth, bounding.width + diff);
      this.$emit("resize");
      this.$parent.$emit("resize", column);
    },
    onCellContextmenu(...params) {
      this.$parent.$emit("header-contextmenu", ...params);
    },
    getScrollPosition() {
      return Position.from(this.$refs.headerScroll);
    },
    scrollTo({ left = 0 }) {
      const elm = this.$refs.headerScroll;
      if (elm) elm.scrollLeft = left;
      // check if scrollable
      const pos = this.getScrollPosition();
      this.hasScroll = pos.left > 0;
    },
    calcStyle() {
      const { cellWidth, indexCellWidth, headerCellHeight } = config;
      const { fixedColumns, staticColumns, renderColumns } = this;
      const fixedWidth = calcWidth(fixedColumns);
      const staticWidth = calcWidth(staticColumns);
      const groupOffset = calcGroupOffset(this.groupData);

      /**
       * 计算各部分容器尺寸
       */
      const fixedView = {
        width: `${fixedWidth + indexCellWidth + groupOffset}px`,
        height: headerCellHeight + "px"
      };
      // ⚠️ 因为 body 中的多级分组右侧留有 gutter，这里为了对齐，要加上 offset
      const scrollView = {
        width: staticWidth + groupOffset + "px",
        height: headerCellHeight + "px"
      };
      const shadowLine = { left: fixedView.width };
      /**
       * 计算单元格尺寸和位置
       */
      const cellStyleMap = {};
      // 索引列单元格
      cellStyleMap[-1] = {
        width: indexCellWidth + groupOffset + "px",
        height: headerCellHeight + "px",
        top: 0,
        left: 0
      };
      // 固定列单元格
      for (const column of fixedColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        cellStyleMap[column.columnIndex] = {
          width: columnWidth + "px",
          height: headerCellHeight + "px",
          left: column.columnLeft + indexCellWidth + groupOffset + "px",
          top: 0
        };
      }
      // 其余列单元格
      for (const column of renderColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        cellStyleMap[column.columnIndex] = {
          width: columnWidth + "px",
          height: headerCellHeight + "px",
          left: column.columnLeft + "px",
          top: 0
        };
      }
      // ⚠️ 最后一个单元格宽度加上 offset 以保持跟多级分组时的 body 对齐
      const lastColumn = renderColumns[renderColumns.length - 1];
      if (lastColumn) {
        const cell = cellStyleMap[lastColumn.columnIndex];
        cell.width = `calc(${cell.width} + ${groupOffset}px)`;
      }

      this.viewStyleMap = Object.freeze({ fixedView, scrollView, shadowLine });
      this.cellStyleMap = Object.freeze(cellStyleMap);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
@import (reference) "./style.less";

.pane-header {
  .cell {
    border-top: @border;
  }
  .cell-index {
    color: fade(black, 45%);
    justify-content: center;
    border-left: @border;
    border-right: none;
    border-top-left-radius: @border-radius;
  }
  .cell[data-last-col] {
    border-top-right-radius: @border-radius;
  }
  // 阴影线
  .shadow-line {
    position: absolute;
    animation: antFadeIn 0.3s;
    border-left: @border;
    // 因为 border 自身占 1px，所以需要减 1px
    margin-left: @edge-left - 1px;
    top: 0;
    bottom: 0;
    box-shadow: 2px 0 6px #d5d5d5;
    z-index: @scrollbar-zindex - 3;
  }
  .resize-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    left: -10px;
    transform: scaleX(1.5);
    cursor: col-resize;
    pointer-events: none;
    background-color: @blue-5;
    z-index: @scrollbar-zindex - 1;
  }
}
</style>
