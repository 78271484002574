<template>
  <div class="pane-wrapper ndl-padding" style="width: 280px">
    <FormLabel text="列名称" />
    <a-input
      v-model="model.columnName"
      v-focus
      placeholder="请输入列名称"
      @pressEnter="onConfirm()"
    />
    <FormLabel text="公式" margin-top />
    <FormulaEditor v-model="model.formula" />
    <div class="ndl-flex ndl-flex--end ndl-margin-top">
      <a-button class="ndl-margin-right-sm" @click="hideContext()">
        取消
      </a-button>
      <a-button type="primary" :disabled="!model.columnName" @click="done()">
        完成
      </a-button>
    </div>
  </div>
</template>

<script>
import { focus } from "@/utils/directives";
import FormLabel from "@/views/datamodel/components/form-label";
import FormulaEditor from "@/views/datamodel/components/formula-editor";

export default {
  components: { FormLabel, FormulaEditor },
  directives: { focus },
  props: {
    onConfirm: Function,
    // contextify 组件注入的 prop
    hideContext: Function
  },
  data() {
    return {
      model: {
        columnName: "公式列",
        formula: ""
      }
    };
  },
  methods: {
    done() {
      if (this.onConfirm) {
        this.onConfirm(this.model);
      }
      this.hideContext();
    }
  }
};
</script>
