import * as Model from "./model";
import { Types } from "@/constant/column-types";

const group = new Model.Group({ label: "日期处理" });

group.add({
  name: "NOW",
  returns: Types.date,
  readme: "当前时间戳",
  usage: "NOW()",
  example: ["NOW()\n", "=> 2022-12-23 14:39:53.662522-05"].join("")
});

group.add({
  name: "TODAY",
  returns: Types.date,
  readme: "当前日期",
  usage: "TODAY()",
  example: [
    "TODAY()\n",
    "=> 2022-12-23\n\n",
    "IF({A} = TODAY(), 'Yes', 'No')"
  ].join("")
});

export default group;
