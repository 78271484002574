<template>
  <div class="pane-wrapper ndl-padding" style="width: 280px">
    <FormLabel>{{ column.columnType | locale }}转换</FormLabel>
    <!-- 选择函数 -->
    <a-select
      v-model="func"
      :get-popup-container="() => $el"
      allow-clear
      class="ndl-display-block"
      placeholder="请选择函数"
    >
      <a-select-option
        v-for="(item, index) in expressions"
        :key="index"
        :value="item.func"
        @click.native="onFuncChange(item)"
      >
        {{ item.label }}
      </a-select-option>
    </a-select>
    <!-- 填写参数 -->
    <component
      :is="argEditorComponent"
      :args="args"
      :func="func"
      :columns="columns"
      :get-popup-container="() => $el"
    />
    <div class="ndl-flex ndl-flex--end ndl-margin-top">
      <a-button class="ndl-margin-right-sm" @click="hide()">
        取消
      </a-button>
      <a-button type="primary" @click="done()">
        完成
      </a-button>
    </div>
  </div>
</template>

<script>
import Types from "@/constant/column-types";
import Argument from "@/model/expression-argument";

import expressions from "@/views/datamodel/utils/column-expressions";
import { findExpression } from "@/views/datamodel/utils/column-expressions";

// 参数编辑器
import ArgEditorCommon from "./ArgEditorCommon";
import ArgEditorDateAdd from "./ArgEditorDateAdd";
import ArgEditorDateDiff from "./ArgEditorDateDiff";
import FormLabel from "../../form-label";

function initArgs(args, columnId) {
  if (!args) return null;
  return args.map(item => {
    const arg = Argument.from(item);
    if (!arg.columnId) arg.columnId = columnId;
    return arg;
  });
}

export default {
  components: { FormLabel },
  directives: { focus },
  props: {
    defaultFunc: String,
    column: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    onConfirm: Function
  },
  data() {
    const current = findExpression(this.defaultFunc);
    const defaultArgs = initArgs(current.args, this.column.columnId);
    // 如已存在的和当前的是同一个函数，则合并参数
    const exist = this.column.expression || {};
    if (exist.func === this.defaultFunc) {
      Argument.merge(defaultArgs, exist.args);
    }
    return {
      // 当前列已存在的表达式
      defaultExpression: findExpression(exist.func),
      func: this.defaultFunc,
      args: defaultArgs
    };
  },
  computed: {
    // 字段类型转换可能导致已选的表达式不在当前类型的默认表达式列表里面
    // 因此要从所有类型的表达式里面找出选中的函数，并去重
    expressions() {
      const defaultList = new Set(
        expressions[this.column.columnType] || expressions[Types.text]
      );
      if (this.defaultExpression) defaultList.add(this.defaultExpression);
      return Array.from(defaultList);
    },
    argEditorComponent() {
      const map = {
        TIMESTAMPADD: ArgEditorDateAdd,
        TIMESTAMPDIFF: ArgEditorDateDiff
      };
      return map[this.func] || ArgEditorCommon;
    }
  },
  filters: {
    locale(type) {
      switch (type) {
        case Types.number:
          return "数值";
        case Types.date:
          return "日期";
        default:
          return "文本";
      }
    }
  },
  watch: {
    func(value) {
      if (!value) this.args = null;
    }
  },
  methods: {
    hide() {
      this.$parent.hide();
    },
    done() {
      const expression = { func: this.func, args: this.args || null };
      if (this.onConfirm) this.onConfirm(expression);
      this.hide();
    },
    onFuncChange(expression) {
      this.args = initArgs(expression.args, this.column.columnId);
    }
  }
};
</script>
