import * as Model from "./model";
import workspace from "@/views/datamodel/models/workspace";
import { serializeIndex } from "@/utils/string";

const group = new Model.Group({ label: "引用列" });

group.setDynamicChildrenProvider(function() {
  const columns = workspace.currentDatasheet.columns || [];
  return columns.map((column, index) => {
    const formula = new Model.Formula();
    formula.name = column.columnName;
    formula.alias = serializeIndex(index);
    formula.snippet = `{${formula.alias}}`;
    formula.usage = [
      `{${formula.alias}}`,
      "\n# 或者\n",
      `{${formula.name}}`
    ].join("");
    formula.example = formula.usage;
    formula.returns = column.columnType;
    formula.readme = `引用「${column.columnName}」列相应单元格的值`;
    formula.isFunction = false;
    return formula;
  });
});

export default group;
