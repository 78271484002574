<template>
  <div class="ndl-padding" style="width: 220px">
    <FormLabel>
      起始行
      <a-tooltip title="默认从 0 开始计算">
        <i class="ndl-icon-info"></i>
      </a-tooltip>
    </FormLabel>
    <a-input v-model="topn.start" placeholder="起始行号，默认为 0" />
    <FormLabel margin-top>
      结束行
      <a-tooltip title="留空或输入“n”表示最后一行">
        <i class="ndl-icon-info"></i>
      </a-tooltip>
    </FormLabel>
    <a-input
      v-model="topn.end"
      placeholder="结束行号，默认为 n"
      @pressEnter="onEnter()"
    />
  </div>
</template>

<script>
import FormLabel from "@/views/datamodel/components/form-label";

export default {
  name: "context-topn",
  inheritAttrs: false,
  components: { FormLabel },
  props: {
    topn: {
      type: Object,
      required: true
    }
  },
  methods: {
    onEnter() {
      this.$parent.hide();
    }
  }
};
</script>
