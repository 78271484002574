<template>
  <div class="doc">
    <div class="formula">
      <Group
        v-for="(item, index) in groups"
        :key="index"
        :group="item"
        :focused-formula="formula"
        @focus="formula = $event"
        @select="onSelect"
      />
    </div>
    <Readme class="help" :formula="formula" />
  </div>
</template>

<script>
import doc from "./doc";
import Group from "./ModalDocGroup";
import Readme from "./ModalDocReadme";

export default {
  components: { Group, Readme },
  data() {
    return { formula: void 0 };
  },
  computed: {
    groups() {
      return doc.groups;
    }
  },
  created() {
    doc.on("focus", this.onFocus);
  },
  destroyed() {
    doc.off();
  },
  methods: {
    onSelect(formula) {
      doc.emit("select", formula);
    },
    async onFocus(token) {
      if (token.type === "function") {
        const value = String(token.value).toUpperCase();
        this.formula = doc
          .getSupportedFunctions()
          .find(item => item.name === value);
      } else {
        this.formula = doc
          .getAllFormulas()
          .filter(item => !item.isFunction)
          .find(
            item => item.name === token.value || item.alias === token.value
          );
      }
      await this.$nextTick();
      if (this.formula) {
        const selector = `[data-id="${this.formula.id}"]`;
        const elm = this.$el.querySelector(selector);
        if (elm) {
          elm.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.doc {
  display: flex;
  .formula,
  .help {
    flex: 1 1 auto;
    overflow: auto;
    border-right: solid 1px #ebebeb;
  }
  .formula {
    width: 140px;
  }
  .help {
    width: 220px;
  }
}
</style>
