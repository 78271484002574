import Types from "../define/column-types";

export function getTextCellValue(meta, column, record) {
  return record[meta.colId] || null;
}

export function getFunctionCellValue(meta, column) {
  const options = column.columnOptions || {};
  const cellData = options.cellData || {};
  return cellData[meta.row] || null;
}

/**
 * 获取单元格原始值，公式列和普通单元格逻辑不一样
 * @param {Object} meta 单元格行列信息
 * @param {Array} columns 全部列
 * @param {Array} data 全部记录
 * @param {Boolean} byVisualValue 返回用户可见的值，或真实值（例如公式列单元格真实值是公式，可见值是公式计算结果）
 */
export function getCellValue(
  meta = {},
  { columns = [], data = [] },
  byVisualValue = false
) {
  const column = columns.find(item => item.columnId === meta.colId) || {};
  const record = data[meta.row] || {};
  const visualValue = getTextCellValue(meta, column, record);
  if (byVisualValue) return visualValue;
  switch (column.columnType) {
    case Types.function:
      return getFunctionCellValue(meta, column, record);
    default:
      return visualValue;
  }
}

/**
 * 一个向上查找 elm 所在单元格的工具函数
 * @param {HTMLElement} target 目标元素
 */
export function findCell(target, selector = ".cell.cell-body") {
  let elm = target;
  let cell = null;
  while (elm) {
    if (elm.matches && elm.matches(selector)) {
      cell = elm;
      break;
    }
    elm = elm.parentNode;
  }
  return cell;
}
