import * as Model from "./model";
import { Types } from "@/constant/column-types";

const group = new Model.Group({ label: "条件控制" });

group.add({
  name: "IF",
  returns: Types.text,
  snippet: "IF(${1:logical}, ${2:true}, ${3:false})$0",
  readme:
    "判断是否满足某个条件，如果满足则返回第一个值，如果不满足则返回第二个值。",
  usage: "IF(logical, value1, value2)",
  example: [
    "IF(\n  {A} > 18,\n  '成年人',\n  '未成年'\n)\n\n",
    [
      "IF(\n",
      "  {A} > 18,\n",
      "  IF(\n",
      "    {A} < 60,\n",
      "    '中年人',\n",
      "    '老年人'\n",
      "  ),\n",
      "  '未成年'\n",
      ")"
    ].join("")
  ].join("")
});

export default group;
