<template>
  <div class="ndl-margin-top">
    <FormLabel>日期显示格式</FormLabel>
    <a-select
      v-model="options.dayFmt"
      allow-clear
      :get-popup-container="() => $el"
      option-label-prop="label"
      class="ndl-display-block"
      placeholder="请选择日期格式"
    >
      <a-select-opt-group
        v-for="item in formatList"
        :key="item.group"
        :title="item.group"
      >
        <a-select-option
          v-for="option in item.children"
          :key="option.value"
          :value="option.value"
          :label="option.desc || option.label"
          class="ndl-flex"
        >
          <template v-if="option.desc">
            <span class="ndl-flex-item--grow">{{ option.desc }}</span>
            <span class="ndl-text-desc-small">{{ option.label }}</span>
          </template>
          <template v-else>{{ option.label }}</template>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </div>
</template>

<script>
import dayjs from "dayjs";
import FormLabel from "../../form-label";

export default {
  inheritAttrs: false,
  components: { FormLabel },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    const day = dayjs();
    const labor = (value, desc) => ({ value, desc, label: day.format(value) });
    return {
      formatList: [
        {
          group: "常用格式",
          children: [
            labor("YYYY-MM-DD HH:mm:ss", "日期时间"),
            labor("YYYY-MM-DD", "年-月-日"),
            labor("YYYY-w周", "年-周"),
            labor("YYYY-MM", "年-月"),
            labor("YYYY-Q季", "年-季度"),
            labor("YYYY", "年份")
          ]
        },
        {
          group: "其它",
          children: [
            labor("M", "月份"),
            labor("MM-DD", "月-日"),
            labor("Q", "季度"),
            labor("dddd", "星期几"),
            labor("w", "周"),
            labor("D", "日"),
            labor("H", "时"),
            labor("m", "分"),
            labor("s", "秒")
          ]
        },
        {
          group: "更多",
          children: [
            labor("YYYY年MM月DD日"),
            labor("MM月DD日"),
            labor("HH时mm分ss秒"),
            labor("HH:mm:ss"),
            labor("d", "星期几0-6数值形式"),
            labor("第Q季"),
            labor("x", "UNIX 时间戳")
          ]
        }
      ]
    };
  }
};
</script>
