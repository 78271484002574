<template>
  <div class="pane-body">
    <!-- 在 fixed view 监听 scroll 会减少一些滚动事件处理，因为不存在横向滚动条 -->
    <div class="grid-view is-fixed" ref="bodyFixed" @scroll.passive="onScroll">
      <div class="cell-wrapper" :style="viewStyleMap.fixedView">
        <CellGroup
          v-for="(item, index) in renderGroup"
          :key="'group_fixed_' + index"
          :group="item"
          :columns="fixedColumns"
          fixed
        />
        <template v-for="row in renderData">
          <div
            class="cell cell-index"
            :key="keyof(row.rowIndex, -1)"
            :style="getCellStyle(row.rowIndex, -1)"
            :data-row="row.rowIndex"
            data-col="-1"
            @mouseenter="onCellHover($event, true)"
            @mouseleave="onCellHover($event, false)"
          >
            {{ row.rowIndex }}
          </div>
          <div
            v-for="item in fixedColumns"
            :key="keyof(row.rowIndex, item.columnIndex)"
            class="cell cell-body"
            :style="getCellStyle(row.rowIndex, item.columnIndex)"
            :data-row="row.rowIndex"
            :data-col="item.columnIndex"
            :data-col-id="item.columnId"
            :data-editable="item.columnType === 'function'"
            data-is-fixed="true"
            @mouseenter="onCellHover($event, true)"
            @mouseleave="onCellHover($event, false)"
          >
            <component
              :is="item | getRender"
              :value="row[item.columnId]"
              :key="keyof(row.rowIndex, item.columnIndex)"
              :column="item"
              :record="row"
              :row-index="row.rowIndex"
              :col-index="item.columnIndex"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="grid-view is-scroll" ref="bodyScroll">
      <div class="cell-wrapper" :style="viewStyleMap.scrollView">
        <CellGroup
          v-for="(item, index) in renderGroup"
          :key="'group_scroll_' + index"
          :group="item"
          :columns="renderColumns"
          :parent-style="viewStyleMap.scrollView"
        />
        <template v-for="row in renderData">
          <div
            v-for="(item, staticIndex) in renderColumns"
            :key="keyof(row.rowIndex, item.columnIndex)"
            class="cell cell-body"
            :style="getCellStyle(row.rowIndex, item.columnIndex)"
            :data-row="row.rowIndex"
            :data-col="item.columnIndex"
            :data-col-id="item.columnId"
            :data-static-col="staticIndex"
            :data-editable="item.columnType === 'function'"
            @mouseenter="onCellHover($event, true)"
            @mouseleave="onCellHover($event, false)"
          >
            <component
              :is="item | getRender"
              :value="row[item.columnId]"
              :key="keyof(row.rowIndex, item.columnIndex)"
              :column="item"
              :record="row"
              :row-index="row.rowIndex"
              :col-index="item.columnIndex"
            />
          </div>
        </template>
      </div>
    </div>
    <CellEditor
      ref="cellEditor"
      :columns="columns"
      :data="data"
      @move="onCellEditorMove"
      @confirm="onCellEditorConfirm"
    />
    <!--
      单元格 hover 时，高亮显示对应行，因为存在滚动渲染，这里借助 style 标签实现效果好点 ~
      又因为 vue template 内不允许使用 style 标签，所以用了 component
    -->
    <component :is="'style'" ref="style" />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import Position from "./model/scroll-position";
import mixinPane from "./helper/mixin-pane";
import mixinCell from "./helper/mixin-body-cell";
import {
  calcWidth,
  calcGroupOffset,
  calcGridViewheight
} from "./helper/util-calc";
import { Render } from "./define/column-types";
import CellEditor from "./cell-editor";
import config from "./config";

const keyof = (rowIndex, colIndex) => rowIndex + "," + colIndex;
const defaultCellStyle = "visibility: hidden";

export default {
  mixins: [mixinPane, mixinCell],
  components: { CellEditor },
  filters: {
    getRender(column) {
      return Render[column.columnType] || Render.text;
    }
  },
  mounted() {
    const container = this.$el;
    if (!container) return;
    const debounced = debounce(this.onContainerResize, 300);
    const observer = new ResizeObserver(debounced);
    observer.observe(container);
    observer.observe(this.$refs.bodyFixed.firstChild);
    observer.observe(this.$refs.bodyScroll.firstChild);
    this.observer = observer;
    // 初始化滚动条位置
    this.onContainerResize();
  },
  beforeDestroy() {
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    keyof,
    onContainerResize() {
      this.$emit("resize", this.getScrollPosition());
    },
    onCellHover: debounce(function(evt, bool = false) {
      const el = evt.target;
      const style = this.$refs.style;
      if (!el || !style) return;
      if (!bool) {
        style.innerHTML = null;
        return;
      }
      const row = el.getAttribute("data-row");
      const innerHTML = `.cell[data-row="${row}"] { background: var(--hover-background) }`;
      if (innerHTML !== style.innerHTML) style.innerHTML = innerHTML;
    }, 60),
    getScrollPosition() {
      return Position.from(this.$refs.bodyScroll);
    },
    scrollTo({ left = 0, top = 0 }) {
      const bodyScroll = this.$refs.bodyScroll;
      const bodyFixed = this.$refs.bodyFixed;
      if (bodyScroll) {
        bodyScroll.scrollTop = top;
        bodyScroll.scrollLeft = left;
      }
      if (bodyFixed) bodyFixed.scrollTop = top;
    },
    onScroll: throttle(
      function(evt) {
        const {
          offsetHeight = 0,
          scrollHeight = 0,
          scrollTop = 0
        } = evt.target;
        const lastScrollTop = this.__lastScrollTop__ || 0;
        this.__lastScrollTop__ = scrollTop;
        // 向上滚动时不触发
        if (scrollTop <= lastScrollTop) return;
        // 滚动距离底部 500px 时触发事件
        if (scrollHeight - scrollTop - offsetHeight > 500) return;
        this.$parent.$emit("scroll-bottom", evt);
      },
      100,
      { leading: true, trailing: true }
    ),
    calcStyle() {
      const { cellWidth, indexCellWidth, cellHeight } = config;
      const { fixedColumns, staticColumns, renderColumns, renderData } = this;
      const fixedWidth = calcWidth(fixedColumns);
      const staticWidth = calcWidth(staticColumns);
      const groupOffsetLeft = calcGroupOffset(this.groupData);
      const groupIndexMap = this.getGroupIndexMap();
      const gridViewHeight = calcGridViewheight(this.groupData, this.data);

      /**
       * 计算各部分容器尺寸
       */
      const fixedView = {
        width: `${fixedWidth + indexCellWidth + groupOffsetLeft}px`,
        height: gridViewHeight + "px"
      };
      // ⚠️ scroll-view 宽度加上 groupOffsetLeft，实现多级分组时右侧的 gutter
      const scrollView = {
        width: staticWidth + groupOffsetLeft + "px",
        height: gridViewHeight + "px"
      };
      /**
       * 计算单元格尺寸和位置
       */
      const cellStyleMap = {};
      // 索引列单元格
      for (const record of renderData) {
        const rowIndex = record.rowIndex;
        const renderGroup = groupIndexMap[rowIndex];
        cellStyleMap[keyof(rowIndex, -1)] = `
          width: ${indexCellWidth}px;
          height: ${cellHeight}px;
          top: ${renderGroup.offsetTopOfCell(rowIndex)}px;
          left: ${groupOffsetLeft}px;
        `;
      }
      // 固定列单元格
      for (const column of fixedColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        const { columnIndex, columnLeft } = column;
        for (const record of renderData) {
          const rowIndex = record.rowIndex;
          const renderGroup = groupIndexMap[rowIndex];
          cellStyleMap[keyof(rowIndex, columnIndex)] = `
            width: ${columnWidth}px;
            height: ${cellHeight}px;
            left: ${columnLeft + indexCellWidth + groupOffsetLeft}px;
            top: ${renderGroup.offsetTopOfCell(rowIndex)}px;
          `;
        }
      }
      // 其余列单元格
      for (const column of renderColumns) {
        const columnWidth = column.columnWidth || cellWidth;
        const { columnIndex, columnLeft } = column;
        for (const record of renderData) {
          const rowIndex = record.rowIndex;
          const renderGroup = groupIndexMap[rowIndex];
          cellStyleMap[keyof(rowIndex, columnIndex)] = `
            width: ${columnWidth}px;
            height: ${cellHeight}px;
            left: ${columnLeft}px;
            top: ${renderGroup.offsetTopOfCell(rowIndex)}px;
          }`;
        }
      }

      this.viewStyleMap = Object.freeze({ fixedView, scrollView });
      this.cellStyleMap = Object.freeze(cellStyleMap);
      // 更新滚动位置后检查单元格是否处于 focus 状态
      this.focusOnCell();
    },
    getCellStyle(rowIndex, colIndex) {
      return this.cellStyleMap[keyof(rowIndex, colIndex)] || defaultCellStyle;
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";
@import (reference) "./style.less";

// 底部边距
.pane-body {
  --hover-background: @primary-color-light;
  .cell-index {
    color: fade(black, 45%);
    font-size: 12px;
    justify-content: center;
    border-right: none;
  }
  .cell-wrapper {
    padding-bottom: @edge-bottom;
  }
  .grid-view.is-fixed .cell[data-col="-1"] {
    border-left: @border;
  }
  // scroll-view 中的第一列单元格，编辑器 left 为 0
  // 否则边框会被遮挡一部分，看起来难受
  .cell[data-static-col="0"] .cell-editor::before {
    left: 0;
  }
  .cell[data-row="0"] .cell-editor::before {
    top: 0;
  }
}
</style>
