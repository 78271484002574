<template>
  <div
    :class="['codeblock', { wrapline }]"
    v-on="$listeners"
    v-html="codeHtml"
  ></div>
</template>

<script>
export default {
  props: {
    code: String,
    placeholder: {
      type: String,
      default: "请输入..."
    },
    wrapline: Boolean
  },
  computed: {
    codeHtml() {
      const code = String(this.code || "");
      if (!code && this.placeholder) {
        return `<span class="placeholder">${this.placeholder}</span>`;
      }
      const html = code
        .replace(/".*?"/g, `<span token-string>$&<span>`)
        .replace(/'.*?'/g, `<span token-string>$&</span>`)
        .replace(/(\w+)\(/g, `<span token-predefined>$1</span>(`)
        .replace(/\{[_a-zA-Z\u4e00-\u9fa5]+\}/g, `<span token-ref>$&</span>`)
        .replace(/#.*|=>.*/gm, `<span token-comment>$&</span>`);
      return html;
    }
  }
};
</script>

<style lang="less" scoped>
.codeblock {
  background: #f5f7fa;
  // color: #5786ff;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  font-size: 13px;
  padding: 8px 11px;
  border-radius: 4px;
  overflow: auto;
  white-space: pre;
  cursor: text;
  &.wrapline {
    white-space: pre-wrap;
    line-break: anywhere;
  }
  /deep/ .placeholder {
    color: #bfbfbf;
    user-select: none;
  }
  /deep/ [token-string] {
    color: #ff0000;
  }
  /deep/ [token-ref] {
    color: #800000;
  }
  /deep/ [token-predefined] {
    color: #c700c7;
  }
  /deep/ [token-comment] {
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
