import { flatten } from "@/utils/array";

/**
 * 缓存分组展开状态，避免每次操作后分组都全部展开
 */
export default (function() {
  let cacheData = {};
  let cacheNodeId = null;
  const singleton = {
    /**
     * 清空缓存
     */
    clear() {
      cacheData = {};
      cacheNodeId = null;
    },
    /**
     * 根据缓存恢复分组的展开状态
     * @param {Array} groupData 分组数据
     * @param {String} nodeId 视图 ID，切换视图时清空缓存
     * @returns {Boolean} 返回是否使用缓存
     */
    set(groupData, nodeId) {
      // id 和当前缓存的 id 不一致，说明缓存无效
      const cacheKey = nodeId;
      if (cacheNodeId !== nodeId) {
        cacheData = {};
        cacheData[cacheKey] = groupData;
        cacheNodeId = nodeId;
        return false;
      }
      // 当前视图 ID 不在缓存中
      if (!cacheData[cacheKey]) {
        cacheData[cacheKey] = groupData;
        return false;
      }
      function keyof(group) {
        if (!group) return "";
        let parentKey = keyof(group.parent);
        if (parentKey) parentKey += ".";
        const thisKey = `${group.columnId}[${group.value}]`;
        return parentKey + thisKey;
      }
      // 建立缓存分组的展开状态表
      const cacheMap = flatten(cacheData[cacheKey], "groups").reduce(
        (map, item) => {
          map[keyof(item)] = item.expanded;
          return map;
        },
        {}
      );
      // 遍历当前分组，恢复其展开状态
      for (const group of flatten(groupData, "groups")) {
        const key = keyof(group);
        if (key in cacheMap) {
          group.expanded = cacheMap[key];
        }
      }
      cacheData[cacheKey] = groupData;
      return true;
    }
  };
  return singleton;
})();
