import CellConfig from "../config";
import { findCell } from "../cell-editor/utils";

/**
 * body cell 中的相关操作，如移动单元格、编辑单元格
 */
export default {
  data() {
    return { focusedCell: null };
  },
  created() {
    document.addEventListener("click", this.onCellClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onCellClick);
  },
  methods: {
    onCellClick(evt) {
      const editor = this.$refs.cellEditor;
      if (!editor) return;
      const cell = findCell(evt.target);
      editor.focus(cell);
      this.focusedCell = cell
        ? { row: cell.dataset.row, col: cell.dataset.col }
        : null;
    },
    onCellEditorConfirm(...params) {
      this.$parent.$emit("cell-change", ...params);
    },
    onCellEditorMove(col, row, dirX = 0, dirY = 0) {
      const column = this.columns[col];
      const record = this.data[row];
      if (!column || !record) return;
      const pos = this.getScrollPosition();
      this.focusedCell = { col, row };
      const colLeft = column.columnLeft;
      const colWidth = column.columnWidth || CellConfig.cellWidth;
      const colTop = row * CellConfig.cellHeight;
      const isColumnVisible =
        column.fixed ||
        (colLeft >= pos.left &&
          colLeft + colWidth <= pos.left + pos.offsetWidth);
      const isRowVisible =
        colTop >= pos.top &&
        colTop + CellConfig.cellHeight <= pos.top + pos.offsetHeight;
      if (isColumnVisible && isRowVisible) {
        this.focusOnCell();
      } else {
        pos.left = colLeft < pos.left ? colLeft : pos.left + dirX * colWidth;
        pos.top += dirY * CellConfig.cellHeight;
        this.$emit("scroll", pos);
      }
    },
    async focusOnCell() {
      await this.$nextTick();
      const nextCell = this.focusedCell;
      if (!nextCell) return;
      const selector = `.cell.cell-body[data-row="${nextCell.row}"][data-col="${nextCell.col}"]`;
      const cellElm = this.$el.querySelector(selector);
      const editor = this.$refs.cellEditor;
      if (!cellElm || !editor) return;
      editor.focus(cellElm);
    }
  }
};
