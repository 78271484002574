<template>
  <span :class="['checkbox', { checked }]" @click="onClick()">
    <svg
      v-if="checked"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1.05em"
      height="1.0em"
      fill="#fff"
    >
      <path
        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    checked: Boolean
  },
  model: {
    prop: "checked"
  },
  methods: {
    onClick() {
      const val = !this.checked;
      this.$emit("input", val);
      this.$emit("change", val);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.checkbox {
  width: 1.3em;
  height: 1.3em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 1px fade(black, 15%);
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    border-color: @blue-6;
    background: @blue-1;
  }
  &.checked {
    background: @blue-6;
    border-color: @blue-6;
  }
}
</style>
