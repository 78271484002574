import showContextmenu from "@/components/contextmenu";
import { ContextOption } from "@/components/contextmenu";
import { Define } from "@/views/datamodel/utils/column-options.js";
import Types from "@/constant/column-types";

// websocket and actions
import workspace from "@/views/datamodel/models/workspace";
import Actions from "@/constant/datasheet-actions";

const optionsMap = {
  [Types.text]: [
    new ContextOption("agg", "不展示").$value(null),
    Define.AGG_COUNT,
    Define.AGG_DISTINCT
  ],
  [Types.number]: [
    new ContextOption("agg", "不展示").$value(null),
    Define.AGG_SUM.$label("求和"),
    Define.AGG_AVG,
    Define.AGG_MAX,
    Define.AGG_MIN,
    Define.AGG_COUNT,
    Define.AGG_DISTINCT
  ],
  [Types.date]: []
};

optionsMap[Types.date] = optionsMap[Types.text];

/**
 * 展示统计类型菜单
 * @param {Object} column 工作表列
 * @param {MouseEvent} evt 事件
 */
export default async function(column, source) {
  const options = optionsMap[column.columnType] || optionsMap[Types.text];
  const config = { source, dir: { x: true }, inverse: { x: true } };
  const option = await showContextmenu({ options, width: "160px" }, config);
  const action = Actions.stats({
    columnId: column.columnId,
    statsType: option.value
  });
  workspace.currentDatasheet.send(action);
}
