<template>
  <div
    class="cell cell-header"
    :data-col="index"
    :data-col-id="column.columnId"
    :title="column.columnName"
    @contextmenu.prevent="onContextmenu"
    @click="onContextmenu"
    @touchmove.prevent.stop
    @touchend.prevent.stop="onContextmenu"
    @mousedown="onDragMouseDown"
  >
    <i class="cell-icon" :class="column.columnType | columnIcon"></i>
    <span class="cell-label">{{ column.columnName }}</span>
    <!-- 聚合操作指示 -->
    <span v-if="column.aggType" class="unit blue">{{ column.aggType }}</span>
    <!-- 日期格式化指示 -->
    <span v-if="dateFormat" class="unit orange">{{ dateFormat }}</span>
    <!-- 排序指示 -->
    <i v-if="sortType" class="unit green" :class="sortType | sortIcon"></i>
    <span class="cell-fit"></span>
    <span class="cell-ref">{{ index | toChar }}</span>
    <span class="cell-resizer" @mousedown="onResizeMouseDown"></span>
  </div>
</template>

<script>
import { serializeIndex } from "@/utils/string";
import { Icon } from "../define/column-types";
import resize from "../helper/mixin-header-resize";
import move from "../helper/mixin-header-move";

export default {
  mixins: [resize, move],
  inject: ["sheetRoot"],
  props: {
    index: {
      type: Number,
      default: 0
    },
    column: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      resizing: false,
      dragging: false,
      dateFormatMap: {
        "YYYY-MM-DD HH:mm:ss": "time",
        "YYYY-MM-DD": "day",
        "YYYY-w周": "week",
        "YYYY-MM": "month",
        "YYYY-Q季": "quarter",
        YYYY: "year"
      }
    };
  },
  computed: {
    columnOptions() {
      return this.column.columnOptions || {};
    },
    sortType() {
      const root = this.sheetRoot || {};
      const types = root.sortTypes || {};
      return types[this.column.columnId];
    },
    dateFormat() {
      const dateFormatMap = {
        "YYYY-MM-DD HH:mm:ss": "time",
        "YYYY-MM-DD": "day",
        "YYYY-w周": "week",
        "YYYY-MM": "month",
        "YYYY-Q季": "quarter",
        YYYY: "year"
      };
      const format = this.columnOptions.dayFmt;
      return dateFormatMap[format];
    }
  },
  filters: {
    toChar: serializeIndex,
    columnIcon(type) {
      return Icon[type];
    },
    sortIcon(type) {
      if (type === "asc") return "ndl-icon-arrow-up";
      return "ndl-icon-arrow-down";
    }
  },
  methods: {
    onContextmenu(evt) {
      // resize-mixin 和 move-mixin 里面会更新 resizing 和 dragging 状态
      if (this.resizing || this.dragging) {
        return;
      }
      this.$emit("contextmenu", this.column, evt, this.$el);
    }
  }
};
</script>

<style scoped lang="less">
.cell-header {
  cursor: pointer;
  .cell-icon {
    margin-right: 5px;
    flex-shrink: 0;
  }
  .cell-label {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .unit {
    font-size: 12px;
    margin: 0 6px;
    font-weight: bold;
    transform: scale(0.9);
    transform-origin: bottom;
    & + .unit {
      margin-left: -3px;
    }
  }
  .blue {
    color: rgb(87, 134, 255);
  }
  .orange {
    color: rgb(255, 123, 0);
  }
  .green {
    color: rgb(48, 192, 137);
    transform: none;
  }
  .cell-fit {
    flex-grow: 1;
  }
  .cell-ref {
    color: #d0d0d0;
    flex-shrink: 0;
    font-size: 12px;
  }
  .cell-resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
  }
}
</style>
