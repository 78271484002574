<script>
import SpreadSheet, { Group } from "@/components/spread-sheet";
import Actions from "@/constant/datasheet-actions";
import WorksheetSocket from "@/views/datamodel/utils/worksheet-socket";
import workspace from "@/views/datamodel/models/workspace";
import debounce from "lodash/debounce";

import groupCache from "./group-cache";
import onChangeStatisticType from "./column-statistic";
import showContextmenu from "./show-contextmenu";
import showGroupExpandContextmenu from "./group-expand-contextmenu";

export default {
  computed: {
    currentPage() {
      return workspace.currentDatasheet.page;
    },
    groupData() {
      const data = Group.from(workspace.currentDatasheet.groupData || []);
      groupCache.set(data, workspace.currentDatasheetId);
      return data;
    },
    sortTypes() {
      const sort = workspace.currentDatasheet.sort || {};
      const conditions = sort.conditions || [];
      return conditions.reduce((map, item) => {
        map[item.columnId] = item.sortType;
        return map;
      }, {});
    }
  },
  watch: {
    $route() {
      this.$refs.spreadSheet.reset();
    },
    currentPage(page) {
      // page 设为 1 后重置滚动条位置
      if (page <= 1) {
        this.$refs.spreadSheet.reset();
      }
    }
  },
  methods: {
    showContextmenu,
    onChangeStatisticType,
    onGroupContextmenu(...params) {
      showGroupExpandContextmenu(this.groupData, ...params);
    },
    // 前端只负责发请求，一般不修改数据，方便操作失败时撤销修改
    onCellChange(cellValue, { column, row }) {
      const action = Actions.cell({ ...column, rowIndex: row, cellValue });
      workspace.currentDatasheet.send(action);
    },
    onMoveColumn(orders) {
      const action = Actions.move({ orders });
      workspace.currentDatasheet.send(action);
    },
    onResizeColumn(column) {
      const action = Actions.resize(
        column,
        // disableLoading 不会触发加载动画
        chunk => (chunk.disableLoading = true)
      );
      workspace.currentDatasheet.send(action);
    },
    onScrollBottom: debounce(async function() {
      const datasheet = workspace.currentDatasheet;
      // 已分组时忽略分页加载
      if (datasheet.group.conditions.length) {
        return;
      }
      // 正在加载中或者已加载全部数据，忽略下一页动作
      if (datasheet.loadingPageData) {
        return;
      }
      // 给当前视图添加 loadingPageData 标记
      datasheet.loadingPageData = true;
      try {
        const action = Actions.page(
          { page: datasheet.page + 1, size: WorksheetSocket.PAGE_SIZE },
          chunk => (chunk.disableLoading = true)
        );
        await datasheet.send(action);
      } finally {
        datasheet.loadingPageData = false;
      }
    }, 200)
  },
  render() {
    const currentDatasheet = workspace.currentDatasheet;
    return (
      <SpreadSheet
        ref="spreadSheet"
        columns={currentDatasheet.columns}
        data={currentDatasheet.data}
        group-data={this.groupData}
        statistics={currentDatasheet.statistics}
        total={currentDatasheet.total}
        sort-types={this.sortTypes}
        onHeader-contextmenu={this.showContextmenu}
        onCell-change={this.onCellChange}
        onMove={this.onMoveColumn}
        onResize={this.onResizeColumn}
        onScroll-bottom={this.onScrollBottom}
        onGroup-contextmenu={this.onGroupContextmenu}
        onChange-statistic-type={onChangeStatisticType}
      />
    );
  }
};
</script>
