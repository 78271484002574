<template>
  <div
    class="sheet-container"
    @wheel.prevent="onWheel"
    @touchmove.prevent="onTouchMove"
    @touchend.prevent="onTouchEnd"
  >
    <!-- table header -->
    <PaneHeader
      v-bind="bindOptions"
      :render-columns="renderColumns"
      :render-data="renderData"
      :render-group="renderGroup"
      ref="paneHeader"
      @resize="recalcStyle()"
    />
    <!-- table body -->
    <PaneBody
      v-bind="bindOptions"
      :render-columns="renderColumns"
      :render-data="renderData"
      :render-group="renderGroup"
      ref="paneBody"
      @resize="onResize"
      @scroll="onBodyScroll"
      @group-expand="updateFlattenedGroups"
      @group-contextmenu="onGroupContextmenu"
      @group-statistic="onChangeStatisticType"
    />
    <!-- table footer -->
    <PaneFooter
      v-bind="bindOptions"
      :render-columns="renderColumns"
      :render-data="renderData"
      :render-group="renderGroup"
      ref="paneFooter"
      @click="onChangeStatisticType"
    />
    <!-- scrollbar x -->
    <div
      class="scrollbar-thumb scrollbar-x"
      ref="scrollbarX"
      @mousedown="dragScrollbar('scrollbarX', $event)"
    ></div>
    <!-- scrollbar y -->
    <div
      class="scrollbar-thumb scrollbar-y"
      ref="scrollbarY"
      @mousedown="dragScrollbar('scrollbarY', $event)"
    ></div>
  </div>
</template>

<script>
import PaneHeader from "./PaneHeader";
import PaneBody from "./PaneBody";
import PaneFooter from "./PaneFooter";
import mixinScroll from "./helper/mixin-scroll";
import mixinViewport, { defaultScrollPosition } from "./helper/mixin-viewport";
import mixinGroup from "./helper/mixin-group";
import calcWidth from "./helper/util-calc";

export default {
  mixins: [mixinScroll, mixinViewport, mixinGroup],
  components: { PaneHeader, PaneBody, PaneFooter },
  provide() {
    return { sheetRoot: this };
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    // 列排序方式，用于表头显示排序图标
    sortTypes: {
      type: Object,
      default: () => {}
    },
    // 分组数据
    groupData: {
      type: Array,
      default: () => []
    },
    // 统计数据
    statistics: Object,
    total: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    bindOptions() {
      const columns = this.columns.filter(item => item.visible);
      columns.forEach((item, index) => (item.columnIndex = index));
      // 找出最后一个固定列，默认固定第一列
      // 并将 columns 分为 fixedColumns 和 staticColumns 两部分
      let lastIndex = columns.length - 1;
      while (columns[lastIndex] && !columns[lastIndex].fixed) {
        lastIndex--;
      }
      // 默认固定第一列
      const fixedIndex = Math.max(0, lastIndex) + 1;
      const fixedColumns = columns.slice(0, fixedIndex);
      for (const column of fixedColumns) {
        column.fixed = true;
      }
      return {
        columns,
        fixedColumns,
        staticColumns: columns.slice(fixedIndex),
        data: this.data,
        total: this.total,
        groupData: this.groupData,
        statistics: this.statistics || {}
      };
    },
    // 生成 columnId => column 的表，用于快速查找列
    columnIdMap() {
      return this.columns.reduce((map, item) => {
        map[item.columnId] = item;
        return map;
      }, {});
    }
  },
  watch: {
    bindOptions() {
      this.rerender();
    },
    flattenedGroups() {
      this.rerender();
    }
  },
  methods: {
    async rerender(pos) {
      if (!pos) {
        pos = this.$refs.paneBody.getScrollPosition();
      }
      this.updateViewportContent(pos);
      this.recalcStyle();
    },
    recalcStyle() {
      const { fixedColumns, staticColumns } = this.bindOptions;
      // calcWidth 会缓存计算结果，避免滚动时大量计算导致卡顿，调整列宽后需清除缓存
      calcWidth(fixedColumns, false);
      calcWidth(staticColumns, false);
      this.$refs.paneHeader.calcStyle();
      this.$refs.paneBody.calcStyle();
      this.$refs.paneFooter.calcStyle();
    },
    // 重置表格滚动位置，并重新计算渲染数据
    async reset() {
      this.syncPaneScrollPosition(defaultScrollPosition);
      this.recalcStyle();
      await this.$nextTick();
      const bodyRef = this.$refs.paneBody;
      // nextTick 后 body 有可能已经被销毁了，加个判断避免报错
      if (bodyRef) {
        const pos = bodyRef.getScrollPosition();
        this.updateScrollbar(pos);
      }
    },
    onResize(pos) {
      this.rerender(pos);
      this.updateScrollbar(pos);
    },
    onGroupContextmenu(...params) {
      this.$emit("group-contextmenu", ...params);
    },
    onChangeStatisticType(...params) {
      this.$emit("change-statistic-type", ...params);
    }
  }
};
</script>

<style lang="less" src="./style.less"></style>
