import showContextmenu from "@/components/contextmenu";
import { ContextOption } from "@/components/contextmenu";

export default async function(groups, group, source, callback) {
  const options = [
    group.expanded
      ? new ContextOption("col", "收起分组", "minus") // collapse
      : new ContextOption("exp", "展开分组", "plus"), // expand
    new ContextOption("exp-sub", "展开子分组", "plus-square"),
    new ContextOption("col-sub", "收起子分组", "minus-square", true),
    new ContextOption("exp-all", "展开所有分组", "maximize-2"),
    new ContextOption("col-all", "收起所有分组", "minimize-2")
  ];
  const option = await showContextmenu({ options, width: "160px" }, { source });
  switch (option.key) {
    case "col":
      group.toggle(false);
      break;
    case "exp":
      group.toggle(true);
      break;
    case "exp-sub":
      group.toggle(true, true);
      break;
    case "col-sub":
      group.toggle(false, true);
      break;
    case "exp-all":
      for (const item of groups || []) {
        if (item && item.toggle) item.toggle(true, true);
      }
      break;
    case "col-all":
      for (const item of groups || []) {
        if (item && item.toggle) item.toggle(false, true);
      }
      break;
  }
  if (callback) callback();
}
