<template>
  <div class="pane-wrapper ndl-padding">
    <div class="sort-header ndl-text-desc-small ndl-margin-bottom">
      <span>排序设置</span>
    </div>
    <Draggable
      :list="sort.conditions"
      :animation="200"
      handle=".handle"
      class="sort-row"
      ref="wrapper"
    >
      <div
        class="sort-item"
        v-for="(item, index) in sort.conditions"
        :key="index"
      >
        <i class="handle ndl-icon-move-drag"></i>
        <SelectColumn
          v-model="item.columnId"
          :columns="computedColumns"
          :get-popup-container="() => $el"
          class="sort-column"
        />
        <a-radio-group
          v-model="item.sortType"
          button-style="solid"
          class="ndl-radio-group"
        >
          <a-radio-button value="asc">升序</a-radio-button>
          <a-radio-button value="desc">降序</a-radio-button>
        </a-radio-group>
        <i class="sort-remove ndl-icon-x" @click="remove(index)"></i>
      </div>
    </Draggable>
    <SelectColumn
      v-model="selectedColumn"
      :columns="computedColumns"
      :get-popup-container="() => $el"
      class="sort-column"
      placeholder="请选择列"
      @change="add"
    />
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import SelectColumn from "@/views/datamodel/components/select-column";

export default {
  components: { Draggable, SelectColumn },
  props: {
    columns: {
      type: Array,
      required: true
    },
    sort: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // 不能为 null，否则不显示 placeholder
      selectedColumn: void 0
    };
  },
  computed: {
    computedColumns() {
      const columns = this.columns || [];
      const conditions = this.sort.conditions || [];
      const selected = conditions.reduce((map, item) => {
        map[item.columnId] = true;
        return map;
      }, {});
      return columns.map(item => ({
        columnId: item.columnId,
        columnType: item.columnType,
        columnName: item.columnName,
        disabled: selected[item.columnId]
      }));
    }
  },
  methods: {
    async add(columnId) {
      const cond = { columnId, sortType: "asc" };
      this.sort.conditions.push(cond);
      this.selectedColumn = void 0;
      await this.$nextTick();
      const wrapper = this.$refs.wrapper.$el;
      // 自动滚动到底部
      wrapper.scrollTo({
        top: 1000000,
        left: 0,
        behavior: "smooth"
      });
    },
    remove(index) {
      this.sort.conditions.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.pane-wrapper {
  width: 400px;
  .sort-row {
    padding: 6px 0;
    margin: 6px 0;
    max-height: 380px;
    overflow: auto;
  }
  .sort-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
  }
  .handle {
    position: absolute;
    cursor: move;
    color: #bfbfbf;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }
  .sort-column {
    flex-shrink: 0;
    width: 180px;
    margin-right: 10px;
    margin-left: 26px;
  }
  .sort-remove {
    margin: 0 4px 0 12px;
    color: #bfbfbf;
    cursor: pointer;
    transition: color 0.2s;
    &:hover {
      color: #ff4d4f;
    }
  }
}
</style>
