<template>
  <div class="pane-wrapper ndl-padding" style="width: 280px">
    <FormLabel>编辑列名</FormLabel>
    <a-input
      v-model="name"
      v-focus
      placeholder="请输入列名称"
      @pressEnter="done()"
    />
    <!-- 公式列不允许修改列类型 -->
    <template v-if="showTypeField">
      <FormLabel margin-top>列类型</FormLabel>
      <ColumnType v-model="type" />
    </template>
    <!-- 不同类型的列的特殊选项 -->
    <component :is="optionComp" :column="column" :options="options" />
    <!-- 按钮组 -->
    <div class="ndl-flex ndl-flex--end ndl-margin-top">
      <a-button class="ndl-margin-right-sm" @click="hide()">
        取消
      </a-button>
      <a-button type="primary" :disabled="!name" @click="done()">
        完成
      </a-button>
    </div>
  </div>
</template>

<script>
import { focus } from "@/utils/directives";
import { Types } from "@/constant/column-types";
import { ColumnOption } from "@/model/column";
import FormLabel from "@/views/datamodel/components/form-label";
import ColumnType from "./ColumnType";
import OptionNumber from "./OptionNumber";
import OptionDate from "./OptionDate";
import OptionFunction from "./OptionFunction";
import OptionRadio from "./OptionRadio";

export default {
  components: { ColumnType, FormLabel },
  directives: { focus },
  props: {
    column: {
      type: Object,
      required: true
    },
    onConfirm: Function
  },
  data() {
    const column = this.column || {};
    return {
      name: column.columnName,
      type: column.columnType,
      options: ColumnOption.from(column.columnOptions)
    };
  },
  computed: {
    showTypeField() {
      return this.type !== Types.function;
    },
    optionComp() {
      const map = {
        [Types.date]: OptionDate,
        [Types.number]: OptionNumber,
        [Types.function]: OptionFunction,
        [Types.radio]: OptionRadio
      };
      return map[this.type];
    }
  },
  methods: {
    hide() {
      this.$parent.hide();
    },
    done() {
      if (this.onConfirm) {
        const {
          name: columnName,
          type: columnType,
          options: columnOptions
        } = this;
        this.onConfirm({ columnName, columnType, columnOptions });
      }
      this.hide();
    }
  }
};
</script>
